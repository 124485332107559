import { useState, useEffect } from 'react';
import http from  '../../config/http';
import { FaUpload } from 'react-icons/fa';
import FileUploader from '../../config/uploadFile';

import { useLoading } from '../../context/contextLoading';
import { useToast } from '../../context/contextToast';

import './OptionButtons.css';

const UploadFormFile =  ({onClose, data, onfetchServices})=> {

  const showToast                                 = useToast();
  const { showLoading, hideLoading }              = useLoading();

  // const [selectedFiles, setSelectedFiles]               = useState({
  //   forms: null,
  //   treatmentPlan: null,
  //   printFile: null
  // });
  // const [fileNames, setFileNames]                       = useState({
  //   forms: '',
  //   treatmentPlan: '',
  //   printFile: ''
  // });
  const [ urlTreatmentPlan, setUrlTreatmentPlan ]          = useState('');

  const [formData, setFormData]                         = useState({
    name: data.name,
    healthInfo: data.healthInfo,
    setNumber: 0
  });
  const [ upperMidline, setUpperMidline]                = useState(data.upperMidline);
  const [ lowerMidline, setLowerMidline]                = useState(data.lowerMidline);
  const [ canineRelation, setCanineRelation]            = useState(data.canineRelation);
  const [ molarRelation, setMolarRelation]              = useState(data.molarRelation);
  const [ archesToBeTreated, setArchesToBeTreated]      = useState(data.archesToBeTreated);
  const [treatmentPreferences, setTreatmentPreferences] = useState(data.treatmentPreferences);
  const [isButtonDisabled, setIsButtonDisabled]           = useState(true);


  // const handleFileChange = (e) => {
  //   setSelectedFiles({ ...selectedFiles, [e.target.name]: e.target.files[0] });
  //   setFileNames({ ...fileNames, [e.target.name]: e.target.files[0].name });
  // };

  // const handleIconClick = (e, inputId) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   document.getElementById(inputId).click();
  // };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlerRefused = async() => {
    const response = await http.put(`/services/refused/${data._id}`);
      onClose();
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    try {
      showLoading();
      // var urlForms = await FileUploader(selectedFiles.forms);
      // var urlTreatmentPlan = await FileUploader(selectedFiles.treatmentPlan);
      // var urlPrintFile = await FileUploader(selectedFiles.printFile);
      

      const updatedFormData = {
        forms: '',
        treatmentPlan: urlTreatmentPlan,
        // printFile: urlPrintFile,
        setNumber: formData.setNumber
      };

      await http.put(`/services/myzmiles/${data._id}`, updatedFormData);
      hideLoading();
      onClose();
      onfetchServices();
    } catch (error) {
      showToast('info', 'Notification', error);
      hideLoading();
    }
  };

  useEffect(() => {
    // && selectedFiles.printFile
    if ( 
        formData.setNumber > 0 && urlTreatmentPlan.length > 0
    ) {

    setIsButtonDisabled(false);
    } else {
    setIsButtonDisabled(true);
    }
  }, [formData, urlTreatmentPlan]);

  return (
    <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1200px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Upload Treatment Files</h2>
      <form onSubmit={handlerSubmit} className="space-y-4">

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        {/* <div className="grid grid-cols-1 gap-4">
          <div className="border border-gray-300">
            <div className="p-2 flex justify-between items-center" style={ selectedFiles.treatmentPlan !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
              <label htmlFor="patientPicture" className="flex items-center">
                <span style={{color: 'red'}}>*</span><span>Treatment Plan</span>
                <FaUpload onClick={(e) => handleIconClick(e, 'treatmentPlan')} className="ml-2 cursor-pointer" />
              </label>
              <input type="file" name="treatmentPlan" id="treatmentPlan" style={{ display: 'none' }} onChange={handleFileChange} required />
              <span className="ml-auto">{fileNames.treatmentPlan}</span>
            </div>
          </div>
        </div> */}

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Treatment Plan:</label>
          <input type="text" name="name" id="name" value={urlTreatmentPlan} onChange={ ( e ) => setUrlTreatmentPlan( e.target.value ) } required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        {/* <div className="grid grid-cols-1 gap-4">
          <div className="border border-gray-300">
            <div className="p-2 flex justify-between items-center" style={ selectedFiles.printFile !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
              <label htmlFor="patientPicture" className="flex items-center">
                <span style={{color: 'red'}}>*</span><span>Print File</span>
                <FaUpload onClick={(e) => handleIconClick(e, 'printFile')} className="ml-2 cursor-pointer" />
              </label>
              <input type="file" name="printFile" id="printFile" style={{ display: 'none' }} onChange={handleFileChange} required />
              <span className="ml-auto">{fileNames.printFile}</span>
            </div>
          </div>
        </div> */}

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Set Number:</label>
          <input 
            type="number" 
            name="setNumber" 
            id="setNumber" 
            value={formData.setNumber} onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
            style={ formData.setNumber > 0 ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}
          />
        </div>

        <div>

          <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Current Condition</h2>

          <div className="grid grid-cols-2 gap-4">

            <div className="p-1">
      
              <label>Upper Midline:</label>
              <div className="options-container">
                <div>
                    <label>
                    <input type="radio" value="Centered" checked={upperMidline === 'Centered'} required/>
                    &nbsp;Centered
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Moved to the right" checked={upperMidline === 'Moved to the right'} required/>
                    &nbsp;Moved to the right
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Moved to the Left" checked={upperMidline === 'Moved to the Left'} required/>
                    &nbsp;Moved to the Left
                    </label>
                </div>

              </div>

            </div>

            <div className="p-1">

              <label>Lower Midline:</label>
              <div className="options-container">
                <div>
                    <label>
                    <input type="radio" value="Centered" checked={lowerMidline === 'Centered'} required/>
                    &nbsp;Centered
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Moved to the right" checked={lowerMidline === 'Moved to the right'} required/>
                    &nbsp;Moved to the right
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Moved to the Left" checked={lowerMidline === 'Moved to the Left'} required/>
                    &nbsp;Moved to the Left
                    </label>
                </div>
              </div>

            </div>

          </div>

          <div className="grid grid-cols-2 gap-4">

            <div className="p-1">
              <label>Canine Relation:</label>
              <div className="options-container">
              <div>
                  <label>
                  <input type="radio" value="Class I" checked={canineRelation === 'Class I'} required/>
                  &nbsp;Class I
                  </label>
              </div>
              <div>
                  <label>
                  <input type="radio" value="Class II" checked={canineRelation === 'Class II'} required/>
                  &nbsp;Class II
                  </label>
              </div>
              <div>
                  <label>
                  <input type="radio" value="Class III" checked={canineRelation === 'Class III'} required/>
                  &nbsp;Class III
                  </label>
              </div>
            </div>

            </div>

            <div className="p-1">
              <label>Molar Relation:</label>
              <div className="options-container">
                <div>
                    <label>
                    <input type="radio" value="Class I" checked={molarRelation === 'Class I'} required/>
                    &nbsp;Class I
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Class II" checked={molarRelation === 'Class II'} required/>
                    &nbsp;Class II
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Class III" checked={molarRelation === 'Class III'} required/>
                    &nbsp;Class III
                    </label>
                </div>
              </div>
            </div>

          </div>

          <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Arches to be Treated:</h2>
          <div className="grid grid-cols-2 gap-4">
                    
            <div className="p-1">
              <div className="options-container">
                <div>
                    <label>
                    <input type="radio" value="Both" checked={archesToBeTreated === 'Both'} required/>
                    &nbsp;Both
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Upper" checked={archesToBeTreated === 'Upper'} required/>
                    &nbsp;Upper
                    </label>
                </div>
                <div>
                    <label>
                    <input type="radio" value="Lower" checked={archesToBeTreated === 'Lower'} required/>
                    &nbsp;Lower
                    </label>
                </div>
              </div>
            </div>

          </div>

          <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Treatment Preferences:</h2>

          <div className="grid grid-cols-1 gap-4">
            
            <div className="p-1">
                <div className="checkboxes-container">
                <div>
                    <label>
                    <input type="checkbox" name="improveUpperMidline" checked={treatmentPreferences.improveUpperMidline}
                    /> Improve Upper Midline
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="improveLowerMidline" checked={treatmentPreferences.improveLowerMidline}
                    /> Improve Lower Midline
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="improveOverjet" checked={treatmentPreferences.improveOverjet}
                    /> Improve Overjet
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="improveOverbite" checked={treatmentPreferences.improveOverbite}
                    /> Improve Overbite
                    </label>
                </div>
                </div>
            </div>

            <div className="p-1">
                
                <div className="checkboxes-container">
                <div>
                    <label>
                    <input type="checkbox" name="improveCanineRelation" checked={treatmentPreferences.improveCanineRelation}
                    /> Improve Canine Relation
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="improveMoralRelation" checked={treatmentPreferences.improveMoralRelation}
                    /> Improve Moral Relation
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="improvePosteriorCrossBite" checked={treatmentPreferences.improvePosteriorCrossBite}
                    /> Improve Posterior Cross Bite
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="proclination" checked={treatmentPreferences.proclination}
                    /> Proclination
                    </label>
                </div>
                </div>

            </div>

            <div className="p-1">
                
                <div className="checkboxes-container">
                <div>
                    <label>
                    <input type="checkbox" name="expansion" checked={treatmentPreferences.expansion}
                    /> Expansion
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="distalization" checked={treatmentPreferences.distalization}
                    /> Distalization
                    </label>
                </div>
                <div>
                    <label>
                    <input type="checkbox" name="avoidIPR" checked={treatmentPreferences.avoidIPR}
                    /> Avoid IPR
                    </label>
                </div>
                </div>

            </div>

          </div>

        </div>

        <div>
          <label htmlFor="healthInfo" className="block text-sm font-medium text-gray-700" style={{textTransform: 'capitalize'}}><span style={{color: 'red'}}>*</span>relevant patient health information or other treatment instructions:</label>
          <textarea name="healthInfo" id="healthInfo" value={formData.healthInfo} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
        </div>

        { data.status === 3 ? (
        <>
            <div style={{ border: '1px solid red', padding: '10px', borderRadius: "5px" }}>
                <label>Modification Request</label><br />
                <span style={{fontWeight: 'bold', marginBottom: '8px', padding: '5px' }}>{data.modificationRequest}</span>
            </div>
        </>
        ) 
        : 
        (
        <></>
        ) 
        }
        <div className="flex justify-between">
          <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>

            { data.status !== 3 ? (
              <>
                  <button type="button" onClick={handlerRefused} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white bg-yellow-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Refused
                  </button>
              </>
              ) 
            : 
              (
              <></>
              ) 
            }

          <button 
            type="submit" 
            disabled={isButtonDisabled} 
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 "
          >
          Upload
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadFormFile;