import { useState, useEffect } from 'react';
import http from  '../../config/http';
import FileUploader from '../../config/uploadFile';
import { FaUpload } from 'react-icons/fa';

const ConfirmationOfReceipt =  ({onClose, data, onfetchServices, onSetIsModalOpenConfirm })=> {

    const [isButtonDisabled, setIsButtonDisabled]   = useState(true);
    const [formData, setFormData] = useState({
        name: data.name,
        dob: data.dob.substring(0,10), // Date of birth
        healthInfo: '',
        printFile: null ,
        trackingNumber: ''
    });

    const [selectedFiles, setSelectedFiles] = useState({
        pdfParcel: null
    });
    
    const [fileNames, setFileNames] = useState({ // To store the file names
        pdfParcel: ''
    });

  
    const handleConfirmPrintFile = async() => {

      await http.put(`/services/unlockprintfileconfirme/${data._id}`);
      onClose();
      onfetchServices();
      
    };


    const handleClose = () => {
        // onSetIsModalOpenConfirm(false);
        onClose();
    };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleIconClick = (e, inputId) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById(inputId).click();
    };

    const handleFileChange = (e) => {
        try{
          if( e.target.files.length  > 0 )
          {
            setSelectedFiles({ ...selectedFiles, [e.target.name]: e.target.files[0] });
            setFileNames({ ...fileNames, [e.target.name]: e.target.files[0].name });
          }
        }catch(ex){
          console.log(ex)
        }   
    };


    useEffect(() => {
        if ( 
            formData.trackingNumber && selectedFiles.pdfParcel
          ) {
    
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
      }, [formData, selectedFiles]);


  return (

    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '800px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Confirmation Of Receipt</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
          <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div className="flex justify-between">
          <button type="button" onClick={handleClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
          </button>
          <button type="button" onClick={handleConfirmPrintFile}  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Treatment Received
          </button>
        </div>

      </form>
    </div>
  );
};

export default ConfirmationOfReceipt;