import React, { useState, useEffect } from 'react'
import http from  '../config/http';

import { useLoading } from '../context/contextLoading';
import { useToast } from '../context/contextToast';


const Account = () => {

  const showToast                                 = useToast();
  const { showLoading, hideLoading }              = useLoading();

  const [dataAccount, SetDataAccount]             = useState({
                                                    firstName: localStorage.getItem('firstName'),
                                                    lastName: localStorage.getItem('lastName'),
                                                    email: localStorage.getItem('email'),
                                                    plan_name: localStorage.getItem('plan_name'),
                                                    current_term_starts_at: localStorage.getItem('current_term_starts_at'),
                                                    current_term_ends_at: localStorage.getItem('current_term_ends_at')
                                                  });
  const [ address, SetAddress ]                   = useState('');

  useEffect(() => {
    handlerAddress();
  }, [])
  

  const handleAddressChange = ( e ) => {
    SetAddress(e.target.value);
  };

  const handlerAddress = async() => {
    showLoading();
    const record = await http.get(`/services/address/${localStorage.getItem('tenant')}`);
    if( record.status === 200 ){
      hideLoading();
      SetAddress(record.data.address);
    }else{
      hideLoading();
    }
  };

  const handlerAddressSave = async() => {
    showLoading();
    const record = await http.put(`/services/address/save`,{
      id: localStorage.getItem('tenant'),
      address
    });
    if( record.status === 200 ){
      hideLoading();
      showToast('info', 'Notification', 'It was saved successfully.');
    }else{
      hideLoading();
    }
  };

  return (
    <>

      <div style={{width: '800px', height: '750px', padding:'10px', margin: '20px auto', border: '1px solid black', borderRadius: '5px'}}>

        <h2 style={{ marginBottom: '35px', fontSize: '24px', fontWeight: 'bold'}}>Account</h2>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">First Name:</label>
          <input disabled type="text" name="firstName" value={dataAccount.firstName} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Last Name:</label>
          <input disabled type="text" name="lastName" value={dataAccount.lastName} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Email:</label>
          <input disabled type="text" name="email" value={dataAccount.email} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Plan Name:</label>
          <input disabled type="text" name="plan_name" value={dataAccount.plan_name} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Current Term Starts At:</label>
          <input disabled type="text" name="current_term_starts_at" value={dataAccount.current_term_starts_at} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Current Term Ends At:</label>
          <input disabled type="text" name="current_term_ends_at" value={dataAccount.current_term_ends_at} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <br />
        <hr />
        <br />
        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Address:</label>
          <input type="text" name="address" value={address} onChange={ handleAddressChange } className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
          <button type="button" onClick={handlerAddressSave} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
          </button>
        </div>


      </div>
    </>
  )
}

export default Account