import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import createHttpInstance from '../config/http';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {

    const params = useParams();
    const [formData, setFormData] = useState({
        email: ''
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.cpassword) {
            alert('Passwords do not match!');
            return;
        }
        try {
            // const response = await createHttpInstance.post('/register', formData);
            // console.log(response.data);
            navigate('/login');
        } catch (error) {
            console.error(error);
        }
    };


  return (
    <div className="flex flex-col justify-center font-[sans-serif] text-[#333] sm:h-screen p-4">
      <div className="max-w-md w-full mx-auto border border-gray-300 rounded-md p-6">
        <div className="text-center mb-12">
          <a href="#0"><img src={require('../Ressources/Logo01.png')} alt="logo" className='w-40 inline-block' /></a>
        </div>
        <p>{params.id}</p>
        <form>
          <div className="space-y-6">
            <div>
                <label className="text-sm mb-2 block">Email {params.idResetPassword}</label>
                <input  type="email" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter email" name="email"  onChange={handleChange} value={formData.email}/>
            </div>
          </div>
          <div className="!mt-10">
            <button type="button" onClick={handleSubmit} className="w-full py-3 px-4 text-sm font-semibold rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none">
              Send Mail
            </button>
          </div>
          <p className="text-sm mt-6 text-center">
          Already have an account? 
            <Link to="/login" className="text-blue-600 font-semibold hover:underline ml-1">Login here</Link> {/* Use Link component here */}
          </p>
        </form>
         
      </div>
      
    </div>
  )
}

export default ResetPassword;
