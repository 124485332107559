import React from 'react';

const SupportPage = () => {
  return (
    <div className="max-w-4xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-semibold text-gray-900 mb-8">Support</h1>
      <div className="mb-5">
        {/* <h2 className="text-xl font-medium text-gray-900 mb-2">Call us anytime at:</h2> */}
        {/* Make the phone number bold */}
        {/* <p className="text-lg text-gray-700 font-bold">+1-214-991-2986</p> */}
      </div>
      <div className="mb-5">
        {/* <h2 className="text-xl font-medium text-gray-900 mb-2">Email:</h2> */}
        {/* Make the email clickable and bold */}
        {/* <p className="text-lg text-gray-700">
          <a href="mailto:support@toothx.com" className="font-bold hover:text-blue-600">
            support@toothx.com
          </a>
        </p> */}
      </div>
      <div>
        <h2 className="text-xl font-medium text-gray-900 mb-2">Schedule a Zoom meeting with support:</h2>
        <a href="http://google.com" target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
          Toothx Support Calendly
        </a>
      </div>
    </div>
  );
};

export default SupportPage;
