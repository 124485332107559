import { useState, useEffect } from 'react';
import http from  '../../config/http';
import FileUploader from '../../config/uploadFile';
import { FaUpload } from 'react-icons/fa';
import { Calendar } from 'primereact/calendar';

const ConfirmParcel =  ({onClose, data, onfetchServices, onSetIsModalOpenConfirm })=> {

    const [isButtonDisabled, setIsButtonDisabled]   = useState(true);
    const [formData, setFormData] = useState({
        name: data.name,
        dob: data.dob.substring(0,10), // Date of birth
        healthInfo: '',
        printFile: null ,
        trackingNumber: ''
    });

    const [selectedFiles, setSelectedFiles] = useState({
        pdfParcel: null
    });
    
    const [fileNames, setFileNames] = useState({ // To store the file names
        pdfParcel: ''
    });

    const [ address, SetAddress ]                   = useState('');
    const [ fullName, SetFullName ]                   = useState('');

  
    const handleConfirmPrintFile = async() => {

        var pdfParcel = await FileUploader(selectedFiles.pdfParcel);
        let jsonData = {
            pdfParcel: pdfParcel,
            trackingNumber: formData.trackingNumber
        };

        await http.put(`/services/confirmeparcel/${data._id}`, jsonData);
        onClose();
        onfetchServices();
    };


    const handleClose = () => {
        // onSetIsModalOpenConfirm(false);
        onClose();
    };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleIconClick = (e, inputId) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById(inputId).click();
    };

    const handleFileChange = (e) => {
        try{
          if( e.target.files.length  > 0 )
          {
            setSelectedFiles({ ...selectedFiles, [e.target.name]: e.target.files[0] });
            setFileNames({ ...fileNames, [e.target.name]: e.target.files[0].name });
          }
        }catch(ex){
          console.log(ex)
        }   
    };


    useEffect(() => {
        if ( 
            formData.trackingNumber && selectedFiles.pdfParcel
          ) {
    
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
      }, [formData, selectedFiles]);


    useEffect(() => {
      handlerAddress();
    }, []);

    const handlerAddress = async() => {
      const record = await http.get(`/services/address/${data.tenant}`);
      if( record.status === 200 ){
        SetAddress(record.data.data.address);
        SetFullName(record.data.data.fullName)
      }
    };


    const handlefecha = (e) => {
      const dateString = e;  // Formato: dd/mm/yyyy
      console.log( dateString )
      const [ year, month, day ] = dateString.split("-");  // Separar día, mes y año
      const dateObject = new Date(year, month - 1, day);  // Crear objeto Date (mes - 1 porque los meses en JS empiezan en 0)
      return dateObject;
    };
  

  return (

    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '800px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Confirm Parcel</h2>
      <form className="space-y-4">
        
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        {/* <div>
          <label className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
          <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div> */}

        <div>
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Date of Birth:</label>
            <Calendar 
              name="dob" 
              id="dob" 
              value={handlefecha(formData.dob)} 
              className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
              style={{width: "100%"}}
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
              disabled
              showIcon 
            />
          </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Doctor's Name:</label>
          <input disabled type="text" id="name" name="fullName" value={fullName} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Shipment Address:</label>
          <input disabled type="text" id="name" name="address" value={address} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Tracking Number:</label>
          <input type="text" name="trackingNumber" id="trackingNumber" value={formData.trackingNumber} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div className="p-4 border border-gray-300">
            <div className="flex justify-between items-center mt-1 px-3 py-2 rounded-md" style={ selectedFiles.scan !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
            <label htmlFor="pdfParcel" className="flex items-center">
                <span style={{color: 'red'}}>*</span><span>Pdf Parcel</span>
                <FaUpload onClick={(e) => handleIconClick(e, 'pdfParcel')} className="ml-2 cursor-pointer" />
            </label>
            <input type="file" name="pdfParcel" id="pdfParcel" style={{ display: 'none' }} onChange={handleFileChange} />
            <span className="ml-auto">{fileNames.pdfParcel}</span>
            </div>
        </div>

        <div className="flex justify-between">
          <button type="button" onClick={handleClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
          </button>
          <button type="button" disabled = {isButtonDisabled ? "disabled" : ""} onClick={handleConfirmPrintFile}  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Ok
          </button>
        </div>

      </form>
    </div>
  );
};

export default ConfirmParcel;