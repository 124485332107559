import React, { useState } from 'react';
import http from  '../../config/http';

const ModificationRequest =  ({data, onSetIsModalOpenModifi, onfetchServices})=> {
  const [modification, setModification] = useState('');
  const handleChange = ({target}) => {
    setModification(target.value);
  };

  const [formData, setFormData] = useState({
    name: data.name
  });

  const onCloseS = () => onSetIsModalOpenModifi(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      await http.put(`/services/modification/${data._id}`, {modification});
      onfetchServices();
      onCloseS();

    } catch (error) {
      console.error('Failed to add Service:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold text-center mb-4">Modification Request</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        

        {/* Assuming scan and photo uploads are handled through a different process or updated to use file inputs */}
        <h4><label style={{fontWeight: 'bold', marginBottom: '8px'}}>Modification Request</label></h4>
        <div>
            <label htmlFor="healthInfo" className="block text-sm font-medium text-gray-700">Please list the modifications you would like:</label>
            <textarea name="healthInfo" id="healthInfo" value={modification} onChange={handleChange} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
          </div>
        <div className="flex justify-between">
          <button type="button" onClick={onCloseS} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
          <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModificationRequest;