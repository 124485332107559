import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../config/UserContext';
import AddPatient from './servicesPageComponents/AddPatient';


import { FaUserCircle, FaBell  } from 'react-icons/fa';

const Layout = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false); // State to manage AddPatient modal visibility

  const popupRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useUser();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('tenant');
    logout();
    navigate('/login'); // Redirect to the login page
  };

  const handleSubscription = () => {
    navigate('/subscriptions'); // Redirect to the subscriptions page
  };

  const handleAccount = () => {
    navigate('/account'); // Redirect to the subscriptions page
  };

  // Function to handle option clicks
  const handleOptionClick = (option) => {
    switch (option) {
      case 'logout':
        handleLogout();
        break;
      case 'subscription':
        handleSubscription();
        break;
        case 'account':
        handleAccount();
        break;
      // Handle other options as needed
      default:
        break;
    }
  };

  const onClose = () => setIsAddPatientModalOpen(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const toggleNotifications = () => setIsNotificationsOpen(!isNotificationsOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <nav className="text-white" style={{background: 'black'}}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center py-3 md:space-x-10" >
            <div className="flex space-x-4">
              <Link to="/dashboard" className="text-base font-medium hover:text-blue-200">Dashboard</Link>
              <Link to="/appointments" className="text-base font-medium hover:text-blue-200">Appointments</Link>
              <Link to="/store" className="text-base font-medium hover:text-blue-200">Store</Link>
              <Link to="/support" className="text-base font-medium hover:text-blue-200">Support</Link>
              <Link to="/analytics" className="text-base font-medium hover:text-blue-200">Analytics</Link>
            </div>

            {
              localStorage.getItem('role') === 'Office' ?
              (
                <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-blue-700 hover:bg-blue-800 text-base font-medium text-white px-4 py-2 rounded-md">
                  <button onClick={() => setIsAddPatientModalOpen(true)} className="text-white hover:text-blue-200">Add a new patient</button>
                  </div>
              )
              :
              (<></>)
            }
            {/* Notifications Icon */}
            <div className="relative" ref={notificationRef}>
                <button onClick={toggleNotifications} className="relative focus:outline-none">
                  <FaBell className="text-gray-600 h-6 w-6" />
                  {notifications.length > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-1">
                      {notifications.length}
                    </span>
                  )}
                </button>
                {isNotificationsOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                    <ul className="py-1 text-gray-700">
                      {notifications.slice(0, 5).map((notification, index) => (
                        <li key={index} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                          {notification}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
                  {/* Avatar and Popup */}
                  <div className="relative" ref={popupRef}>

        <button onClick={togglePopup} className="focus:outline-none">
          <FaUserCircle className="text-gray-600 h-8 w-8" /> {/* User icon as avatar */}
        </button>

        {isPopupOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
             <ul className="py-1 text-gray-700">
                { localStorage.getItem('role') === 'Office' ? <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleOptionClick('account')}> Account</li> : <></> }
                { localStorage.getItem('role') === 'Office' ? <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleOptionClick('subscription')}>Subscription</li> : <></>}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleOptionClick('logout')}>Log out</li>
              </ul>
          </div>
        )}
      </div>

      <div style={{ float: 'left', paddingTop: '10px', paddingLeft: '100px' }}>
        <img src={require('../Ressources/Logo Baner.png')} className='w-10 inline-block' style={{  float: 'left' }} />
      </div>

          </div>
     {/* AddPatient Modal */}
     {isAddPatientModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
        <AddPatient onClose={onClose} />
        </div>
      </div>
      )}
        </div>
      </nav>
      {/* Added padding-top (pt-8) to the main content */}
      <main>
        {children}
      </main>
    </div>
  );
};

export default Layout;
