import React, { useEffect, useState }   from 'react';
import http                             from '../config/http';
import { useLoading }                   from '../context/contextLoading';
import { useToast }                     from '../context/contextToast';

const Analytics = () => {
  const showToast                                                                   = useToast();
  const { showLoading, hideLoading }                                                = useLoading();
  const [servicesReal, setServicesReal]                                             = useState([]);
  const [isLoading, setIsLoading]                                                   = useState(false);

  const [analytics, setAnalytics]                                                   = useState({
                                                                                        Pending_Treatment_Plan: 0, Pending_Treatment_Plan_Data: [],
                                                                                        Pending_Doc_Approval: 0, Pending_Doc_Approval_Data: [],
                                                                                        Modification_Requested: 0, Modification_Requested_Data: [],
                                                                                        Print_Files_Requested: 0, Print_Files_Requested_Data: [],
                                                                                        Ready_To_Print: 0, Ready_To_Print_Data: [],
                                                                                        Treatment_Complete: 0, Treatment_Complete_Data: [],
                                                                                        Treatment_Ready: 0, Treatment_Ready_Data: [],
                                                                                        Treatment_In_Process: 0, Treatment_In_Process_Data: [],
                                                                                        Aligners_Ordered: 0, Aligners_Ordered_Data: [],
                                                                                        Processing_Order_For_Aligners: 0, Processing_Order_For_Aligners_Data: [],
                                                                                        Confirmation_Of_Receipt: 0, Confirmation_Of_Receipt_Data: []
                                                                                    });

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setIsLoading(true);
    try {
      showLoading();
      const response = await http.get(`/services/0/`);
      setServicesReal(response?.data?.services.filter( x => x.status >= 0 ) || []);
      if(response?.data?.services.length > 0){

        let Pending_Treatment_Plan = 0;
        let Pending_Doc_Approval = 0;
        let Modification_Requested = 0;
        let Print_Files_Requested = 0;
        let Ready_To_Print = 0;
        let Treatment_Complete = 0;
        let Treatment_Ready = 0;
        let Treatment_In_Process = 0;
        let Aligners_Ordered = 0;
        let Processing_Order_For_Aligners = 0;
        let Confirmation_Of_Receipt = 0;


        let Pending_Treatment_Plan_Data = [];
        let Pending_Doc_Approval_Data = [];
        let Modification_Requested_Data = [];
        let Print_Files_Requested_Data = [];
        let Ready_To_Print_Data = [];
        let Treatment_Complete_Data = [];
        let Treatment_Ready_Data = [];
        let Treatment_In_Process_Data = [];
        let Aligners_Ordered_Data = [];
        let Processing_Order_For_Aligners_Data = [];
        let Confirmation_Of_Receipt_Data = [];


        response?.data?.services.map( (row) => {
        
          if( row.status === 1 ){
            Pending_Treatment_Plan = Pending_Treatment_Plan + 1;
            Pending_Treatment_Plan_Data = [...Pending_Treatment_Plan_Data, row];
          }else if( row.status === 2 ){
            Pending_Doc_Approval = Pending_Doc_Approval + 1;
            Pending_Doc_Approval_Data = [...Pending_Doc_Approval_Data, row];
          }else if( row.status === 3 ){
            Modification_Requested = Modification_Requested + 1;
            Modification_Requested_Data = [...Modification_Requested_Data, row];
          }else if( row.status === 5 ){
            Print_Files_Requested = Print_Files_Requested + 1;
            Print_Files_Requested_Data = [...Print_Files_Requested_Data, row];
          }else if( row.status === 6 ){
            Ready_To_Print = Ready_To_Print + 1;
            Ready_To_Print_Data = [...Ready_To_Print_Data, row];
          }else if( row.status === 7 ){
            Treatment_Complete = Treatment_Complete + 1;
            Treatment_Complete_Data = [...Treatment_Complete_Data, row];
          }else if( row.status === 9 ){
            Treatment_Ready = Treatment_Ready + 1;
            Treatment_Ready_Data = [...Treatment_Ready_Data, row];
          }else if( row.status === 10 ){
            Treatment_In_Process = Treatment_In_Process + 1;
            Treatment_In_Process_Data = [...Treatment_In_Process_Data, row];
          }else if( row.status === 11 ){
            Aligners_Ordered = Aligners_Ordered + 1;
            Aligners_Ordered_Data = [...Aligners_Ordered_Data, row];
          }else if( row.status === 12 ){
            Processing_Order_For_Aligners = Processing_Order_For_Aligners + 1;
            Processing_Order_For_Aligners_Data = [...Processing_Order_For_Aligners_Data, row];
          }else if( row.status === 3 ){
            Confirmation_Of_Receipt = Confirmation_Of_Receipt + 1;
            Confirmation_Of_Receipt_Data = [...Confirmation_Of_Receipt_Data, row];
          }

        } );

        setAnalytics({
          Pending_Treatment_Plan,
          Pending_Doc_Approval,
          Modification_Requested,
          Print_Files_Requested,
          Ready_To_Print,
          Treatment_Complete,
          Treatment_Ready,
          Treatment_In_Process,
          Aligners_Ordered,
          Processing_Order_For_Aligners,
          Confirmation_Of_Receipt,

          Pending_Treatment_Plan_Data,
          Pending_Doc_Approval_Data,
          Modification_Requested_Data,
          Print_Files_Requested_Data,
          Ready_To_Print_Data,
          Treatment_Complete_Data,
          Treatment_Ready_Data,
          Treatment_In_Process_Data,
          Aligners_Ordered_Data,
          Processing_Order_For_Aligners_Data,
          Confirmation_Of_Receipt_Data
        });
        hideLoading();

      }


    } catch (error) {
      console.error('Failed to fetch services:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    // <FileUploader patientname="examplePatientName" sourcefile="scan"/>
    <>
    
      <div style={{textAlign: 'center', width: '870px', margin: '0px auto 0px auto' }}>

        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Pending Treatment Plan</span><br />
          {analytics.Pending_Treatment_Plan}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Pending Doc Approval</span><br />
          {analytics.Pending_Doc_Approval}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Modification Requested</span><br />
          {analytics.Modification_Requested}
        </div>

        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Print Files Requested</span><br />
          {analytics.Print_Files_Requested}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Ready To Print</span><br />
          {analytics.Ready_To_Print}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Treatment Complete</span><br />
          {analytics.Treatment_Complete}
        </div>

        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Treatment Ready</span><br />
          {analytics.Treatment_Ready}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Treatment In Process</span><br />
          {analytics.Treatment_In_Process}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Aligners Ordered</span><br />
          {analytics.Aligners_Ordered}
        </div>

        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Processing Order For Aligners</span><br />
          {analytics.Processing_Order_For_Aligners}
        </div>
        <div style={{width: '250px', textAlign: 'center', float: 'left', margin: '15px', padding: '10px', backgroundColor: 'black', color: 'white', borderRadius: '5px' }}>
          <span>Confirmation Of Receipt</span><br />
          {analytics.Confirmation_Of_Receipt}
        </div>

      </div>
    </>
    
    
  )
}

export default Analytics