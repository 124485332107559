import { useState } from 'react';


const TreatmentPrescription =  ({onClose, data})=> {
  
const [formData, setFormData]                   = useState({
                                                        name: '',
                                                        dob: '',
                                                        healthInfo: data.patient.healthInfo
                                                    });
  const [ upperMidline, setUpperMidline ]                = useState(data.upperMidline);
  const [ lowerMidline, setLowerMidline ]                = useState(data.lowerMidline);
  const [ canineRelation, setCanineRelation ]            = useState(data.canineRelation);
  const [ molarRelation, setMolarRelation ]              = useState(data.molarRelation);
  const [ archesToBeTreated, setArchesToBeTreated ]      = useState(data.archesToBeTreated);
  const [ treatmentPreferences, setTreatmentPreferences ] = useState({
    improveUpperMidline: data.treatmentPreferences.improveUpperMidline,
    improveLowerMidline: data.treatmentPreferences.improveLowerMidline,
    improveOverjet: data.treatmentPreferences.improveOverjet,
    improveOverbite: data.treatmentPreferences.improveOverbite,
    improveCanineRelation: data.treatmentPreferences.improveCanineRelation,
    improveMoralRelation: data.treatmentPreferences.improveMoralRelation,
    improvePosteriorCrossBite: data.treatmentPreferences.improvePosteriorCrossBite,
    proclination: data.treatmentPreferences.proclination,
    expansion: data.treatmentPreferences.expansion,
    distalization: data.treatmentPreferences.distalization,
    avoidIPR:data.treatmentPreferences.avoidIPR
  });


  return (
    <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1000px'}}>
        <h2 className="text-2xl font-bold text-center mb-4">Treatment Prescription</h2>
        <div className="space-y-4">
         

            <div>
        
                <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Current Condition</h2>

                <div className="grid grid-cols-2 gap-4">

                <div className="p-1">
                    
                    <label>Upper Midline:</label>
                    <div className="options-container">
                    <div>
                        <label>
                        <input type="radio" value="Centered" checked={upperMidline === 'Centered'} onChange={() => {}} required/>
                        &nbsp;Centered
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Moved to the right" checked={upperMidline === 'Moved to the right'} onChange={() => {}} required/>
                        &nbsp;Moved to the right
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Moved to the Left" checked={upperMidline === 'Moved to the Left'} onChange={() => {}} required/>
                        &nbsp;Moved to the Left
                        </label>
                    </div>
                    </div>

                </div>

                <div className="p-1">

                    <label>Lower Midline:</label>
                    <div className="options-container">
                    <div>
                        <label>
                        <input type="radio" value="Centered" checked={lowerMidline === 'Centered'} onChange={() => {}} required/>
                        &nbsp;Centered
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Moved to the right" checked={lowerMidline === 'Moved to the right'} onChange={() => {}} required/>
                        &nbsp;Moved to the right
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Moved to the Left" checked={lowerMidline === 'Moved to the Left'} onChange={() => {}} required/>
                        &nbsp;Moved to the Left
                        </label>
                    </div>
                    </div>

                </div>

                </div>

                <div className="grid grid-cols-2 gap-4">
                
                <div className="p-1">
                    <label>Canine Relation:</label>
                    <div className="options-container">
                    <div>
                        <label>
                        <input type="radio" value="Class I" checked={canineRelation === 'Class I'} onChange={() => {}} required/>
                        &nbsp;Class I
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Class II" checked={canineRelation === 'Class II'} onChange={() => {}} required/>
                        &nbsp;Class II
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Class III" checked={canineRelation === 'Class III'} onChange={() => {}} required/>
                        &nbsp;Class III
                        </label>
                    </div>
                    </div>

                </div>

                <div className="p-1">
                    <label>Molar Relation:</label>
                    <div className="options-container">
                    <div>
                        <label>
                        <input type="radio" value="Class I" checked={molarRelation === 'Class I'} onChange={() => {}} required/>
                        &nbsp;Class I
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Class II" checked={molarRelation === 'Class II'} onChange={() => {}} required/>
                        &nbsp;Class II
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Class III" checked={molarRelation === 'Class III'} onChange={() => {}} required/>
                        &nbsp;Class III
                        </label>
                    </div>
                    </div>
                </div>

                </div>

                <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Arches to be Treated:</h2>
                <div className="grid grid-cols-2 gap-4">
                
                <div className="p-1">
                    <div className="options-container">
                    <div>
                        <label>
                        <input type="radio" value="Both" checked={archesToBeTreated === 'Both'} onChange={() => {}} required/>
                        &nbsp;Both
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Upper" checked={archesToBeTreated === 'Upper'} onChange={() => {}} required/>
                        &nbsp;Upper
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="radio" value="Lower" checked={archesToBeTreated === 'Lower'} onChange={() => {}} required/>
                        &nbsp;Lower
                        </label>
                    </div>
                    </div>
                </div>

                </div>
            

            
                <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Treatment Preferences:</h2>

                <div className="grid grid-cols-1 gap-4">
                
                <div className="p-1">
                    <div className="checkboxes-container">
                    <div>
                        <label>
                        <input type="checkbox" name="improveUpperMidline" checked={treatmentPreferences.improveUpperMidline} onChange={() => {}}
                        /> Improve Upper Midline
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="improveLowerMidline" checked={treatmentPreferences.improveLowerMidline} onChange={() => {}}
                        /> Improve Lower Midline
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="improveOverjet" checked={treatmentPreferences.improveOverjet} onChange={() => {}}
                        /> Improve Overjet
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="improveOverbite" checked={treatmentPreferences.improveOverbite} onChange={() => {}}
                        /> Improve Overbite
                        </label>
                    </div>
                    </div>
                </div>

                <div className="p-1">
                    
                    <div className="checkboxes-container">
                    <div>
                        <label>
                        <input type="checkbox" name="improveCanineRelation" checked={treatmentPreferences.improveCanineRelation} onChange={() => {}}
                        /> Improve Canine Relation
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="improveMoralRelation" checked={treatmentPreferences.improveMoralRelation} onChange={() => {}}
                        /> Improve Moral Relation
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="improvePosteriorCrossBite" checked={treatmentPreferences.improvePosteriorCrossBite} onChange={() => {}}
                        /> Improve Posterior Cross Bite
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="proclination" checked={treatmentPreferences.proclination} onChange={() => {}}
                        /> Proclination
                        </label>
                    </div>
                    </div>

                </div>

                <div className="p-1">
                    
                    <div className="checkboxes-container">
                    <div>
                        <label>
                        <input type="checkbox" name="expansion" checked={treatmentPreferences.expansion} onChange={() => {}}
                        /> Expansion
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="distalization" checked={treatmentPreferences.distalization} onChange={() => {}}
                        /> Distalization
                        </label>
                    </div>
                    <div>
                        <label>
                        <input type="checkbox" name="avoidIPR" checked={treatmentPreferences.avoidIPR} onChange={() => {}}
                        /> Avoid IPR
                        </label>
                    </div>
                    </div>

                </div>

                </div>


            </div>

          
            <div>
                <label htmlFor="healthInfo" className="block text-sm font-medium text-gray-700" style={{textTransform: 'capitalize'}}><span style={{color: 'red'}}>*</span>relevant patient health information or other treatment instructions:</label>
                <textarea name="healthInfo" id="healthInfo" value={formData.healthInfo} onChange={() => {}} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
            </div>
            <div className="flex justify-between">
                <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Close
                </button>
            </div>
        </div>
    </div>
  );

};

export default TreatmentPrescription;