export const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
  
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const now = Date.now().valueOf() / 1000;
      if (payload.exp < now) {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error checking token validity", error);
      return false;
    }
  };