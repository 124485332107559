import { useEffect, useState }                              from 'react';
import http                                                 from '../config/http';
import { Calendar as PrimeCalendar }                        from 'primereact/calendar';
import { Dropdown }                                         from 'primereact/dropdown';
import { Calendar, momentLocalizer }                        from 'react-big-calendar'

import "react-big-calendar/lib/css/react-big-calendar.css"
import moment                                               from 'moment'
import { useLoading }                                       from '../context/contextLoading';
import { useToast }                                         from '../context/contextToast';

const localizer = momentLocalizer(moment)

const Appointments = () => {

  const showToast                                           = useToast();
  const { showLoading, hideLoading }                        = useLoading();
  const [ isModalOpen, setIsModalOpen ]                     = useState(false);
  const [ appointments, setAppointments ]                   = useState([]);
  const [ value, onChange ]                                 = useState(new Date());
  const [ patients, setPatients ]                           = useState([]);
  const [ myEventsList, setMyEventsList ]                   = useState([]);
  const [ appointmentData, setAppointmentData ]             = useState({});
  const [ appointmentData_a, setAppointmentData_a ]         = useState('');
  const [ appointmentData_b, setAppointmentData_b ]         = useState('');


  const fetchPatients = async () => {
    try {
      const response = await http.get('/patients');
      setPatients( response.data.map( ( val ) => {
        return{
          patientId: val._id,
          name: val.name
        }
      } ) );
    } catch (error) {
      console.error('Failed to fetch patients:', error);
    }
  };
  

  const fetchAppointments = async () => {
    try {
      const response = await http.get('/appointments');

      if(response.status === 200 ){
        setAppointments( response.data );
      }else{
        setAppointments( [] );
      }
      
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    }
  };
  
  
  useEffect(() => {
    fetchAppointments();
    fetchPatients();
  }, []);


  const handleAddAppointment = async (e) => {
    e.preventDefault();
    try {
      let formattedDate = moment(appointmentData_a).format('YYYY-MM-DDTHH:mm:ss');
      await http.post('/appointments', {
        date: formattedDate,
        meetingType: appointmentData_b,
        patient: appointmentData.patientId
      });
      setAppointmentData({
        patientId: '',
        name: ''
      });
      setAppointmentData_a('');
      setAppointmentData_b('');

      setIsModalOpen(false);
      fetchAppointments();
    } catch (error) {
      console.error('Error adding appointment:', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setAppointmentData({
      patientId: '',
      name: ''
    });
    setAppointmentData_a('');
    setAppointmentData_b('');
  };


  // ===================================================================================
  const eventPropGetter = (event) => {
    let backgroundColor = '';
    let color = '';

    if (event.desc === "aligner appointment") {
      backgroundColor = 'rgba(0, 140, 207, .2)';
      color = 'black'
    }
    if (event.desc === "refinement") {
      backgroundColor = 'rgb(244, 244, 244)';
      color = 'black'
    }
    if (event.desc === "final checkup") {
      backgroundColor = 'rgba(50, 156, 75, .2)';
      color = 'black'
    }

    return {
      style: {
        backgroundColor,
        color,
        borderRadius: '5px',
        border: 'none',
      },
    };
  };

  return (
    
    <div className='p-10'>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Appointments</h1>
        <button
          onClick={handleOpenModal}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Appointment
        </button>
      </div>
      
      <div>
        <Calendar
            localizer={localizer}
            events={appointments}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            defaultView='month'
            views={['month']}
            eventPropGetter={eventPropGetter}
          />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-full max-w-2xl">
            <form onSubmit={handleAddAppointment}>
              <h1 className="text-xl font-semibold mb-4" style={{textAlign: 'center', fontWeight: 'bold'}}>Add Appointment</h1>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Patient</label>
                <Dropdown 
                  value={appointmentData}
                  onChange={(e) => setAppointmentData(e.target.value)}
                  options={patients}
                  optionLabel="name" 
                  placeholder="Select a Patient" 
                  filter 
                  // valueTemplate={selectedCountryTemplate} 
                  // itemTemplate={countryOptionTemplate} 
                  className="w-full md:w-14rem border border-gray-300"
                  required
                />
              </div>
              
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Date</label>
                <PrimeCalendar
                  value={appointmentData_a}
                  onChange={(e) => setAppointmentData_a(e.target.value)}
                  className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
                  style={ appointmentData.date !== '' ? {border: '1px solid green', width: "100%"} : {borderColor: 'rgba(209, 213, 219, 1)', width: "100%"}}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  showTime 
                  hourFormat="24"
                  showIcon 
                />
              </div>


              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Type of Meeting</label>
                <select
                  className="bg-white border border-gray-300 text-sm py-3 rounded-md outline-blue-500 w-full"
                  value={appointmentData_b}
                  onChange={(e) => setAppointmentData_b(e.target.value)}
                  required
                >
                  <option value="">Select option</option>
                  <option value="aligner appointment" style={{backgroundColor: 'rgba(0, 140, 207, .2)'}}>Aligner Appointment</option>
                  <option value="refinement" style={{backgroundColor: 'rgb(244, 244, 244)'}}>Refinement</option>
                  <option value="final checkup" style={{backgroundColor: 'rgba(50, 156, 75, .2)'}}>Final Checkup</option>
                </select>

              </div>
              <div className="flex justify-end space-x-2">
                <button onClick={() => setIsModalOpen(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                  Cancel
                </button>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>

  );
};

export default Appointments;

