import React, { useState, useEffect } from 'react';
import http from  '../../config/http';
import { FaDownload, FaUpload } from 'react-icons/fa';

// import ModificationRequest from './ModificationRequest';

    
  const ReviewTreatment =  ({ onClose, data, onfetchServices, onSetIsModalOpenModifi, onSetIsModalOpenConfirm })=> {
      // const [isModalOpen, setIsModalOpen] = useState(false);
      const [formData, setFormData] = useState({
        name: data.name,
        dob: data.dob.substring(0,10), // Date of birth
        healthInfo: '',
        // Assuming file inputs will be handled separately
      });

      const handleApprove = async() => {
        // await http.put(`/services/approve/${data._id}`);
        // onfetchServices();
        onSetIsModalOpenConfirm(true);
        onClose();
      };


      const handleModification = async() => {
        onSetIsModalOpenModifi(true);
        onClose();
      };

      return (
          <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1400px', height: '830px'}}>
              <h2 className="text-2xl font-bold text-center mb-4">Review Treatment</h2>
            <form >
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Patient Name:</label>
                <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
              </div>
              <div>
                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
                <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
              </div>
              <div>
              
              <div>
              <embed
                  src={data.treatmentplan}
                  type="application/pdf" width="100%" height="520px"
              />
              <div className="flex items-center">Treatment Plan <a href={data.treatmentplan} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a></div>
              </div>
              </div>
              <div>
              </div>
              <div>
              </div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ textAlign: 'right', flex: 'left' }}>

                <div className='inline-flex' style={{ width: '320px' }}>
                <button type="button" onClick={onClose} className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                  Close
                </button>
                </div>

                <button type="button" onClick={handleModification} className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Request Modifications
                </button>
                &nbsp;
                <button type="button" onClick={handleApprove} className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Approve
                </button>
                
              </div>
            </form>

          </div>
        );
    };

    export default ReviewTreatment;