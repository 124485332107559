import axios from 'axios';

const createHttpInstance = axios.create({
  // baseURL: 'http://localhost:7501'
  baseURL: 'https://toothxapi-0wjc.onrender.com'
});


export default createHttpInstance;

// Example getToken function (replace with actual token retrieval logic)