import React, { useState } from 'react';
import http from  '../../config/http';
import { FaUpload } from 'react-icons/fa';
import { Calendar } from 'primereact/calendar';

const ConfirmApproval =  ({onClose, data, onfetchServices, onSetIsModalOpenConfirm })=> {
  
  const [formData, setFormData] = useState({
    id: data._id,
    name: data.name,
    dob: data.dob.substring(0,10), // Date of birth
    healthInfo: '',
    questionPrintFile: 'toothX',

  });

  const [ confirmation, setConfirmation ] = useState(false);

  const handleOnChange = () => {
    setConfirmation(!confirmation);
  };


  const handleOnChangeSelect = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleConfirmPrintFile = async() => {
    if( confirmation ){
      await http.put(`/services/confirmapproval/${data._id}`, formData);
      handleClose();
      onfetchServices();
    }
  };


  const handleClose = () => {
    onSetIsModalOpenConfirm(false);
  };


  const handlefecha = (e) => {
    const dateString = e;  // Formato: dd/mm/yyyy
    console.log( dateString )
    const [ year, month, day ] = dateString.split("-");  // Separar día, mes y año
    const dateObject = new Date(year, month - 1, day);  // Crear objeto Date (mes - 1 porque los meses en JS empiezan en 0)
    return dateObject;
  };


  return (

    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '800px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Confirm Approval</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        {/* <div>
          <label className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
          <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div> */}

        <div>
          <label htmlFor="dob" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Date of Birth:</label>
          <Calendar 
            name="dob" 
            id="dob" 
            value={handlefecha(formData.dob)}
            className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
            style={ formData.dob !== '' ? {border: '1px solid green', width: "100%"} : {borderColor: 'rgba(209, 213, 219, 1)', width: "100%"}}
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
            disabled
            showIcon 
          />
        </div>



        <div>
          <input 
            type="checkbox"
            id="topping"
            name="topping"
            value="Paneer"
            checked={confirmation}
            onChange={handleOnChange}
          />
            <label>By checking this box, I, Dr. {localStorage.getItem('doctor')}, certify that I have reviewed and approved the aligner treatment plan proposed for {data.name}.</label>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Manufacturing:</label>
          <select name='questionPrintFile' value={formData.questionPrintFile} onChange={handleOnChangeSelect} className="bg-white border border-gray-300 text-sm m-4 py-3 rounded-md outline-blue-500" style={{width: '100%'}}>
            <option key={'printfile'} value={'printfile'}>Request Aligner Print Files</option>
            <option key={'toothX'} value={'toothX'}>Order Aligners From ToothX</option>
          </select>
        </div>

        { 
          formData.questionPrintFile === 'toothX' 
          ? (
              <div style={{height: '100px'}}>
                <table>
                  <tbody>
                    <tr index={1}>
                      <td style={{width: '125px'}}>Number of Sets:</td>
                      <td>{data.setNumber}</td>
                    </tr>
                    <tr index={2}>
                      <td style={{width: '125px'}}>Price per Set:</td>
                      <td>$ 25.00 USD</td>
                    </tr>
                    <tr index={3}>
                      <td style={{width: '125px'}}>Total Amount:</td>
                      <td>$ { Number(data.setNumber * 25).toFixed(2) } USD</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) 
          : 
          (
            <div style={{height: '90px'}}>
                <table>
                  <tbody>
                    <tr index={1}>
                      <td style={{width: '125px'}}>Printing Fee:</td>
                      <td>$ 50.00 USD</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              // Envio de correo del costos del print fee
          ) 
        }

        <div className="flex justify-between">
          <button type="button" onClick={handleClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
          </button>
          <button type="button" onClick={handleConfirmPrintFile} disabled = {(!confirmation)? "disabled" : ""} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfirmApproval;