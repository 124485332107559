import axios from 'axios';
import { useUser } from './UserContext';

const http = axios.create({
  // baseURL: 'http://localhost:7501'
  baseURL: 'https://toothxapi-0wjc.onrender.com'
});

// Use an interceptor to modify the headers before each request
http.interceptors.request.use(
  config => {
    // Assuming getToken is a function that retrieves your token
    const token = localStorage.getItem('token') || null;
    const role = localStorage.getItem('role') || null;
    const tenant = localStorage.getItem('tenant') || null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Role = `${role}`;
      config.headers.Tenant = `${tenant}`;
    }
    // You can also add or modify other headers here
    return config;
  },
  error => Promise.reject(error)
);



export default http;

// Example getToken function (replace with actual token retrieval logic)