import { useState, useEffect } from 'react';
// import http from  '../../config/createHttpInstance';
import http from  '../../config/http';
import { FaUpload } from 'react-icons/fa';
import FileUploader from '../../config/uploadFile';

import { useLoading } from '../../context/contextLoading';
import { useToast } from '../../context/contextToast';

import './OptionButtons.css';


import { Calendar } from 'primereact/calendar';

import { FaDownload } from 'react-icons/fa'; // Ensure react-icons is installed

const AddPatient =  ({onClose, data})=> {

    // console.log( data )


  const showToast                                 = useToast();
  const { showLoading, hideLoading }              = useLoading();
  const [isButtonDisabledNew, setIsButtonDisabledNew]   = useState(true);
  const [isButtonDisabled, setIsButtonDisabled]   = useState(true);
  const [formData, setFormData]                   = useState({
    name: data.name,
    dob: new Date(data.dob),
    healthInfo: ''
  });

  const [selectedFiles, setSelectedFiles] = useState({
    scan: data.scan,
    patientPicture: null,
    xray: data.xRay === '' ? null : data.xRay
  });

  const [fileNames, setFileNames] = useState({ // To store the file names
    scan: '',
    patientPicture: '',
    xray: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {

    try{
      if( e.target.files.length  > 0 )
      {
        setSelectedFiles({ ...selectedFiles, [e.target.name]: e.target.files[0] });
        setFileNames({ ...fileNames, [e.target.name]: e.target.files[0].name });
      }
    }catch(ex){
      console.log(e)
      console.log(ex)
    }
    
  };

  const handleIconClick = (e, inputId) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(inputId).click();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoading();
    try {
      
      var urlPatientPicture = 'https://xtoothx.s3.us-east-2.amazonaws.com/uploads/test/2024/scan/P4LVZZTym-%25203106921.png';
      
      console.log( ' -----> 01 ' );

      var urlScan = '';
      if(!selectedFiles.scan){
        urlScan = await FileUploader(selectedFiles.scan);
      }else{
        urlScan = data.scan
      }
      
      console.log( ' -----> 02 ' );

      var urlXRay = '';
      if(!selectedFiles.xray){
        urlXRay = await FileUploader(selectedFiles.xray);
      }else{
        urlXRay = data.xRay
      }
      
      console.log( ' -----> 03 ' );

      const updatedFormData = {
        _id: data._id,
        _idPatiente: data._idPatiente,
        ...formData,
        scanUrl: urlScan,
        patientPictureUrl: urlPatientPicture,
        xRayUrl: urlXRay,
        upperMidline, lowerMidline, canineRelation, molarRelation, archesToBeTreated, treatmentPreferences
      };
      const response = await http.put('/services/savelast', updatedFormData);
      hideLoading();
      onClose();
    } catch (error) {
      showToast('info', 'Notification', error);
      hideLoading();
    }

  };


//   const handleSaveLater = async (e) => {
//     e.preventDefault();
//     // showLoading();
//     try {
//       var urlScan = await FileUploader(selectedFiles.scan);
      
//       var urlXRay = '';
//       if(selectedFiles.xray){
//         urlXRay = await FileUploader(selectedFiles.xray);
//       }
    
//       const updatedFormData = {
//         ...formData,
//         scanUrl: urlScan,
//         xRayUrl: urlXRay
//       };
//       const response = await http.post('/services/savelast', updatedFormData);
//       hideLoading();
//       onClose();

//     } catch (error) {
//       showToast('info', 'Notification', error);
//       hideLoading();
//     }

//   };


  const [ upperMidline, setUpperMidline] = useState('');
  const handleOptionChangeUpperMidline = (event) => {
    setUpperMidline(event.target.value);
  };

  const [ lowerMidline, setLowerMidline] = useState('');
  const handleOptionChangeLowerMidline = (event) => {
    setLowerMidline(event.target.value);
  };

  const [ canineRelation, setCanineRelation] = useState('');
  const handleOptionChangeCanineRelation = (event) => {
    setCanineRelation(event.target.value);
  };

  const [ molarRelation, setMolarRelation] = useState('');
  const handleOptionChangeMolarRelation = (event) => {
    setMolarRelation(event.target.value);
  };

  const [ archesToBeTreated, setArchesToBeTreated] = useState('');
  const handleOptionChangeArchesToBeTreated = (event) => {
    setArchesToBeTreated(event.target.value);
  };

  const [treatmentPreferences, setTreatmentPreferences] = useState({
    improveUpperMidline: false,
    improveLowerMidline: false,
    improveOverjet: false,
    improveOverbite: false,
    improveCanineRelation: false,
    improveMoralRelation: false,
    improvePosteriorCrossBite: false,
    proclination: false,
    expansion: false,
    distalization: false,
    avoidIPR:false
  });
  const handleCheckboxChangeTreatmentPreferences = (event) => {
    const { name, checked } = event.target;
    setTreatmentPreferences((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };


  useEffect(() => {
    if ( 
        formData.name && formData.dob && selectedFiles.scan && upperMidline && lowerMidline && canineRelation && molarRelation && archesToBeTreated &&
        ( 
          treatmentPreferences.improveUpperMidline || treatmentPreferences.improveLowerMidline || treatmentPreferences.improveOverjet || treatmentPreferences.improveOverbite ||
          treatmentPreferences.improveCanineRelation || treatmentPreferences.improveMoralRelation || treatmentPreferences.improvePosteriorCrossBite || treatmentPreferences.proclination ||
          treatmentPreferences.expansion || treatmentPreferences.distalization || treatmentPreferences.avoidIPR
        ) && formData.healthInfo
      ) {

      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData, selectedFiles, upperMidline, lowerMidline, canineRelation, molarRelation, archesToBeTreated, treatmentPreferences]);


  
  useEffect(() => {
    if ( 
        formData.name && formData.dob && selectedFiles.scan
      ) {

      setIsButtonDisabledNew(false);
    } else {
      setIsButtonDisabledNew(true);
    }
  }, [formData, selectedFiles]);



  return (
    <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1000px'}}>
        <h2 className="text-2xl font-bold text-center mb-4">Pending Patient</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Name:</label>
            <input type="text" 
              name="name" 
              id="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
              className="mt-1 block w-full px-3 py-2 bg-white border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
              style={ formData.name !== '' ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}
            />
          </div>
          <div>
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Date of Birth:</label>
            <Calendar 
              name="dob" 
              id="dob" 
              value={formData.dob} 
              onChange={handleChange} 
              className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
              style={ formData.dob !== '' ? {border: '1px solid green', width: "100%"} : {borderColor: 'rgba(209, 213, 219, 1)', width: "100%"}}
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
              showIcon 
            />
          </div>
          {/* Scan Upload */}

          <div className="grid grid-cols-2 gap-4">

            <div className="p-1 border border-gray-300">
              <div className="flex justify-between items-center mt-1 px-3 py-2 rounded-md" style={ selectedFiles.scan !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
                <label htmlFor="scan" className="flex items-center">
                  <span style={{color: 'red'}}>*</span><span>Scan</span>
                  <FaUpload onClick={(e) => handleIconClick(e, 'scan')} className="ml-2 cursor-pointer" />
                </label>
                <input type="file" name="scan" id="scan" style={{ display: 'none' }} onChange={handleFileChange} />
                <span className="ml-auto">{fileNames.scan}</span>
              </div>
            </div>

            <div className="p-1 border border-gray-300">
              <div className="flex justify-between items-center mt-1 px-3 py-2 rounded-md" style={ selectedFiles.xray !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
                <label htmlFor="xray" className="flex items-center">
                <span>X-Ray (optional)</span>
                  <FaUpload onClick={(e) => handleIconClick(e, 'xray')} className="ml-2 cursor-pointer" />
                </label>
                <input type="file" name="xray" id="xray" style={{ display: 'none' }} onChange={handleFileChange} />
                <span className="ml-auto">{fileNames.xray}</span>
              </div>
            </div>

            {/* <div className="p-1 border border-gray-300">
              <div className="flex justify-between items-center mt-1 px-3 py-2 rounded-md" style={ selectedFiles.patientPicture !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
                <label htmlFor="patientPicture" className="flex items-center">
                <span style={{color: 'red'}}>*</span><span>Patient Picture</span>
                  <FaUpload onClick={(e) => handleIconClick(e, 'patientPicture')} className="ml-2 cursor-pointer" />
                </label>
                <input type="file" name="patientPicture" id="patientPicture" style={{ display: 'none' }} onChange={handleFileChange} />
                <span className="ml-auto">{fileNames.patientPicture}</span>
              </div>
            </div> */}

          </div>
          {/* Assuming scan and photo uploads are handled through a different process or updated to use file inputs */}
          
          <div className="p-1 flex items-center">Download Scan 
            <a href={data.scan} download target="_blank" style={{marginRight: '5px'}}>
              <FaDownload className="ml-2 cursor-pointer" />
            </a>
          </div>
        <div>
        
        <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Current Condition</h2>

        <div className="grid grid-cols-2 gap-4">

          <div className="p-1">
            
            <label>Upper Midline:</label>
            <div className="options-container">
              <div>
                <label>
                  <input type="radio" value="Centered" checked={upperMidline === 'Centered'} onChange={handleOptionChangeUpperMidline} required/>
                  &nbsp;Centered
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Moved to the right" checked={upperMidline === 'Moved to the right'} onChange={handleOptionChangeUpperMidline} required/>
                  &nbsp;Moved to the right
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Moved to the Left" checked={upperMidline === 'Moved to the Left'} onChange={handleOptionChangeUpperMidline} required/>
                  &nbsp;Moved to the Left
                </label>
              </div>
            </div>

          </div>

          <div className="p-1">

            <label>Lower Midline:</label>
            <div className="options-container">
              <div>
                <label>
                  <input type="radio" value="Centered" checked={lowerMidline === 'Centered'} onChange={handleOptionChangeLowerMidline} required/>
                  &nbsp;Centered
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Moved to the right" checked={lowerMidline === 'Moved to the right'} onChange={handleOptionChangeLowerMidline} required/>
                  &nbsp;Moved to the right
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Moved to the Left" checked={lowerMidline === 'Moved to the Left'} onChange={handleOptionChangeLowerMidline} required/>
                  &nbsp;Moved to the Left
                </label>
              </div>
            </div>

          </div>

        </div>

        <div className="grid grid-cols-2 gap-4">
          
          <div className="p-1">
            <label>Canine Relation:</label>
            <div className="options-container">
              <div>
                <label>
                  <input type="radio" value="Class I" checked={canineRelation === 'Class I'} onChange={handleOptionChangeCanineRelation} required/>
                  &nbsp;Class I
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Class II" checked={canineRelation === 'Class II'} onChange={handleOptionChangeCanineRelation} required/>
                  &nbsp;Class II
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Class III" checked={canineRelation === 'Class III'} onChange={handleOptionChangeCanineRelation} required/>
                  &nbsp;Class III
                </label>
              </div>
            </div>

          </div>

          <div className="p-1">
            <label>Molar Relation:</label>
            <div className="options-container">
              <div>
                <label>
                  <input type="radio" value="Class I" checked={molarRelation === 'Class I'} onChange={handleOptionChangeMolarRelation} required/>
                  &nbsp;Class I
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Class II" checked={molarRelation === 'Class II'} onChange={handleOptionChangeMolarRelation} required/>
                  &nbsp;Class II
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Class III" checked={molarRelation === 'Class III'} onChange={handleOptionChangeMolarRelation} required/>
                  &nbsp;Class III
                </label>
              </div>
            </div>
          </div>

        </div>

        <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Arches to be Treated:</h2>
        <div className="grid grid-cols-2 gap-4">
          
          <div className="p-1">
            <div className="options-container">
              <div>
                <label>
                  <input type="radio" value="Both" checked={archesToBeTreated === 'Both'} onChange={handleOptionChangeArchesToBeTreated} required/>
                  &nbsp;Both
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Upper" checked={archesToBeTreated === 'Upper'} onChange={handleOptionChangeArchesToBeTreated} required/>
                  &nbsp;Upper
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" value="Lower" checked={archesToBeTreated === 'Lower'} onChange={handleOptionChangeArchesToBeTreated} required/>
                  &nbsp;Lower
                </label>
              </div>
            </div>
          </div>

        </div>
        

        
        <h2 style={{fontWeight: 'bold', paddingBottom: '5px'}}><span style={{color: 'red'}}>*</span>Treatment Preferences:</h2>

        <div className="grid grid-cols-1 gap-4">
          
          <div className="p-1">
            <div className="checkboxes-container">
              <div>
                <label>
                  <input type="checkbox" name="improveUpperMidline" checked={treatmentPreferences.improveUpperMidline} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Upper Midline
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="improveLowerMidline" checked={treatmentPreferences.improveLowerMidline} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Lower Midline
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="improveOverjet" checked={treatmentPreferences.improveOverjet} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Overjet
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="improveOverbite" checked={treatmentPreferences.improveOverbite} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Overbite
                </label>
              </div>
            </div>
          </div>

          <div className="p-1">
            
            <div className="checkboxes-container">
              <div>
                <label>
                  <input type="checkbox" name="improveCanineRelation" checked={treatmentPreferences.improveCanineRelation} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Canine Relation
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="improveMoralRelation" checked={treatmentPreferences.improveMoralRelation} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Moral Relation
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="improvePosteriorCrossBite" checked={treatmentPreferences.improvePosteriorCrossBite} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Improve Posterior Cross Bite
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="proclination" checked={treatmentPreferences.proclination} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Proclination
                </label>
              </div>
            </div>

          </div>

          <div className="p-1">
            
            <div className="checkboxes-container">
              <div>
                <label>
                  <input type="checkbox" name="expansion" checked={treatmentPreferences.expansion} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Expansion
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="distalization" checked={treatmentPreferences.distalization} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Distalization
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="avoidIPR" checked={treatmentPreferences.avoidIPR} onChange={handleCheckboxChangeTreatmentPreferences}
                  /> Avoid IPR
                </label>
              </div>
            </div>

          </div>

        </div>


    </div>

          
          <div>
            <label htmlFor="healthInfo" className="block text-sm font-medium text-gray-700" style={{textTransform: 'capitalize'}}><span style={{color: 'red'}}>*</span>relevant patient health information or other treatment instructions:</label>
            <textarea name="healthInfo" id="healthInfo" value={formData.healthInfo} onChange={handleChange} className="mt-1 block w-full px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
          </div>
          <div className="flex justify-between">
            
            <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Cancel
            </button>
            
            {/* <button 
              type="button" 
              onClick={handleSaveLater}
              disabled={isButtonDisabledNew} 
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 "
            >
              Save Later
            </button> */}

            <button 
              type="submit" 
              disabled={isButtonDisabled} 
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 "
            >
              Submit
            </button>

          </div>
        </form>
    </div>
  );

};

export default AddPatient;