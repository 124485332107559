import React, { useState } from 'react';
import http from  '../../config/http';
import { FaUpload } from 'react-icons/fa';
import FileUploader from '../../config/uploadFile';
import { Calendar } from 'primereact/calendar';

const PrintFile =  ({onClose, data, onfetchServices, onSetIsModalOpenConfirm })=> {
  
  const [formData, setFormData] = useState({
    name: data.name,
    dob: data.dob.substring(0,10), // Date of birth
    healthInfo: '',
    printFile: null 
  });

  const [selectedFiles, setSelectedFiles]               = useState({
    forms: null,
    treatmentPlan: null,
    printFile: null
  });
  const [fileNames, setFileNames]                       = useState({
    forms: '',
    treatmentPlan: '',
    printFile: ''
  });
  
  const handleConfirmPrintFile = async() => {
    
    var urlPrintFile = await FileUploader(selectedFiles.printFile);
    let jsonData = {
      urlPrintFile,
      status: data.status
    }
    await http.put(`/services/unlockprintfile/${data._id}`, jsonData);
    onClose();
    onfetchServices();

  };


  const handleClose = () => {
    // onSetIsModalOpenConfirm(false);
    onClose();
  };


  const handleIconClick = (e, inputId) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(inputId).click();
  };


  const handleFileChange = (e) => {
    setSelectedFiles({ ...selectedFiles, [e.target.name]: e.target.files[0] });
    setFileNames({ ...fileNames, [e.target.name]: e.target.files[0].name });
  };

  const handlefecha = (e) => {
    const dateString = e;  // Formato: dd/mm/yyyy
    console.log( dateString )
    const [ year, month, day ] = dateString.split("-");  // Separar día, mes y año
    const dateObject = new Date(year, month - 1, day);  // Crear objeto Date (mes - 1 porque los meses en JS empiezan en 0)
    return dateObject;
  };


  return (

    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '800px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Print Files</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        
        {/* <div>
          <label className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
          <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div> */}

        <div>
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Date of Birth:</label>
            <Calendar 
              name="dob" 
              id="dob" 
              value={handlefecha(formData.dob)} 
              className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
              style={{width: "100%"}}
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
              disabled
              showIcon 
            />
          </div>

        <div className="grid grid-cols-1 gap-4">
          <div className="border border-gray-300">
            <div className="p-2 flex justify-between items-center" style={ selectedFiles.printFile !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
              <label htmlFor="patientPicture" className="flex items-center">
                <span style={{color: 'red'}}>*</span><span>Print File</span>
                <FaUpload onClick={(e) => handleIconClick(e, 'printFile')} className="ml-2 cursor-pointer" />
              </label>
              <input type="file" name="printFile" id="printFile" style={{ display: 'none' }} onChange={handleFileChange} required />
              <span className="ml-auto">{fileNames.printFile}</span>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button type="button" onClick={handleClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
          </button>
          {/* disabled = {(!confirmation)? "disabled" : ""} */}
          <button type="button" onClick={handleConfirmPrintFile}  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Unlock Print Files
          </button>
        </div>

      </form>
    </div>
  );
};

export default PrintFile;