import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Subscription from './pages/Subscription';
import Account from './pages/Account';
import Analytics from './pages/Analytics';
import Appointments from './pages/Appointments';
import Support from './pages/Support';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import { isTokenValid } from './config/utils';
import { useUser, UserProvider } from './config/UserContext';
// Import other pages

const AppWrapper = () => {
  return (
    <Router>
      <UserProvider>
      <App />
      </UserProvider>
    </Router>
  );
};

const App = () => {
  const location = useLocation();
  const noLayoutPaths = ['/login', '/register', '/forgotpassword', '/resetpassword']; // Paths that should not have the Layout
  const needsLayout = !noLayoutPaths.includes(`/${location.pathname.split('/')[1]}`);
  // console.log( needsLayout )
  const { logout } = useUser(); 

  useEffect(() => {
    if (!isTokenValid() && !noLayoutPaths.includes(location.pathname)) {
      logout();
      // If the token is invalid and the user is not on a login or register page, redirect to login
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('tenant');
      localStorage.removeItem('doctor');

      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('email');

      localStorage.removeItem('customer_id');
      localStorage.removeItem('plan_name');
      localStorage.removeItem('plan_code');
      localStorage.removeItem('name');
      localStorage.removeItem('current_term_starts_at');
      localStorage.removeItem('current_term_ends_at');
      localStorage.removeItem('interval_unit');
      localStorage.removeItem('status');
      localStorage.removeItem('amount');

      window.location.href = '/login';
    }
  }, [location]);

  return (
    <>
      {needsLayout ? (
        <Layout>
          <RoutesWithLayout />
        </Layout>
      ) : (
        <RoutesWithoutLayout />
      )}
    </>
  );
};

const RoutesWithLayout = () => (
  <Routes>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/subscriptions" element={<Subscription />} />
    <Route path="/account" element={<Account />} />
    <Route path="/analytics" element={<Analytics />} />
    <Route path="/appointments" element={<Appointments />} />
    <Route path="/support" element={<Support />} />
    {/* Add more routes that need layout here */}
  </Routes>
);

const RoutesWithoutLayout = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/forgotpassword" element={<ForgotPassword />} />
    <Route path="/resetpassword/:id" element={<ResetPassword />} />
    
    {/* Redirect or other non-layout routes can go here */}
  </Routes>
);

export default AppWrapper;
