import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import createHttpInstance from '../config/createHttpInstance';
import TermsConditions from './Terms&Conditions';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    cpassword: '',
  });

  // Function to toggle the Terms and Conditions modal
  const toggleTerms = () => setIsTermsOpen(!isTermsOpen);

  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.cpassword) {
      alert('Passwords do not match!');
      return;
    }
    try {
      const response = await createHttpInstance.post('/register', formData);
      // Handle the response here

      // console.log(response.data);
      // Redirect to /subscriptions
      navigate('/login');
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const isButtonDisabled = !isTermsAccepted || !formData.firstName || !formData.lastName || !formData.email || !formData.phone;


  return (
    <div className="flex flex-col justify-center font-[sans-serif] text-[#333] sm:h-screen p-4">
      <div className="max-w-md w-full mx-auto border border-gray-300 rounded-md p-6">
        <div className="text-center mb-12">
          <a href="#0"><img src={require('../Ressources/logo.png')} alt="logo" className='w-40 inline-block' /></a>
        </div>
        <form>
          <div className="space-y-6">
            {/* First Name and Last Name */}
            <div className="flex justify-between gap-3">
              <div className="w-1/2">
                <label className="text-sm mb-2 block">First Name</label>
                <input type="text" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="First Name"  name="firstName"  onChange={handleChange} value={formData.firstName}/>
              </div>
              <div className="w-1/2">
                <label className="text-sm mb-2 block">Last Name</label>
                <input type="text" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Last Name" name="lastName"  onChange={handleChange} value={formData.lastName} />
              </div>
            </div>
            {/* Email and Phone */}
            <div className="flex justify-between gap-3">
              <div className="w-1/2">
                <label className="text-sm mb-2 block">Email</label>
                <input  type="email" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter email" name="email"  onChange={handleChange} value={formData.email}/>
              </div>
              <div className="w-1/2">
                <label className="text-sm mb-2 block">Phone</label>
                <input type="tel" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter phone number" name="phone"  onChange={handleChange} value={formData.phone} />
              </div>
            </div>
            {/* Password and Confirm Password */}
            <div className="flex justify-between gap-3">
              <div className="w-1/2">
                <label className="text-sm mb-2 block">Password</label>
                <input  type="password" className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter password" name="password"  onChange={handleChange} value={formData.password}/>
              </div>
              <div className="w-1/2">
                <label className="text-sm mb-2 block">Confirm Password</label>
                <input name="cpassword" type="password"  onChange={handleChange} value={formData.cpassword} className="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter confirm password" />
              </div>
            </div>
            <div className="flex items-center">
            <input id="remember-me" type="checkbox" checked={isTermsAccepted} onChange={handleTermsChange} className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
            <label htmlFor="remember-me" className="ml-3 block text-sm">
              I accept the <button type="button" onClick={toggleTerms} className="text-blue-600 font-semibold hover:underline">Terms and Conditions</button>
            </label>
          </div>
          </div>
          <div className="!mt-10">
            <button type="button"  disabled={isButtonDisabled} onClick={handleSubmit} className="w-full py-3 px-4 text-sm font-semibold rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none">
              Create an account
            </button>
          </div>
          <p className="text-sm mt-6 text-center">
          Already have an account? 
            <Link to="/login" className="text-blue-600 font-semibold hover:underline ml-1">Login here</Link> {/* Use Link component here */}
          </p>
        </form>
         {/* Modal for Terms and Conditions */}
         {isTermsOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg max-w-md mx-auto">
              {/* TermsConditions component content */}
              <TermsConditions />
              <button onClick={toggleTerms} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      
    </div>
  )
}

export default Register;
