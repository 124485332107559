import React, { useState, useEffect } from 'react';
import http from  '../../config/http';

import status from '../../json/status.json';

const History = ({onClose, data, onfetchServices}) => {

    const [services, setServices] = useState([]);

    useEffect(() => {
        handlerRecords();
      }, []);


    const handlerRecords = async() => {
        const record = await http.get(`/services/history/${data.patient._id}`);
        setServices(record.data.data);
    };
    

    return(
        <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1200px', height: '800px'}}>
            <h2 className="text-2xl font-bold text-center mb-4">Patient history view</h2>

            <div style={{border: '1px solid gray', height: '660px', overflow: 'auto', margin: '10px', padding:'10px'}}>
                <ol>
                    {
                        services.map( (row, index) => (
                            <li>
                                { index +1 }.- <label> Status { status.find( e => e.status === row.status && e.role === localStorage.getItem('role') ).display } <span style={{fontWeight: 'bold'}}>{row.content}</span> {row.createdAt.replace('T', ' ').substring(0, 19) } </label>
                            </li>
                        ))
                    }
                </ol>
            </div>

            <div className="flex justify-between">
                <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Treatment In Process
                </button>
            </div>        
        </div>
    )
}


export default History;