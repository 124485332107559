import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import createHttpInstance from '../config/createHttpInstance';
import { useUser } from '../config/UserContext';

import { useLoading } from '../context/contextLoading';
import { useToast } from '../context/contextToast';

const Login = () => {
  const showToast                                 = useToast();
  const { showLoading, hideLoading }              = useLoading();
  const [isButtonDisabled, setIsButtonDisabled]   = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { login }               = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      showLoading();
      const response = await createHttpInstance.post('/login', formData);
      if (response.status === 401) {
        hideLoading();
        showToast('info', 'Notification', 'Incorrect email or password');
      } else {
        // Store JWT token in local storage
        if( response.data.role === 'Office' ){
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('tenant', response.data.tenant);
          localStorage.setItem('doctor', `${response.data.firstName} ${response.data.lastName}`);
          localStorage.setItem('firstName', `${response.data.firstName}`);
          localStorage.setItem('lastName', `${response.data.lastName}`);
          localStorage.setItem('email', `${response.data.email}`);
          
          localStorage.setItem('customer_id', `${response.data.customer_id}`);
          localStorage.setItem('plan_name', `${response.data.plan_name}`);
          localStorage.setItem('plan_code', `${response.data.plan_code}`);
          localStorage.setItem('name', `${response.data.name}`);
          localStorage.setItem('current_term_starts_at', `${response.data.current_term_starts_at}`);
          localStorage.setItem('current_term_ends_at', `${response.data.current_term_ends_at}`);
          localStorage.setItem('interval_unit', `${response.data.interval_unit}`);
          localStorage.setItem('status', `${response.data.status}`);
          localStorage.setItem('amount', `${response.data.amount}`);
          localStorage.setItem('currentDate', `${response.data.currentDate}`);
          login(response.data);
          hideLoading();
          navigate('/dashboard');
        }else{
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('tenant', response.data.tenant);
          localStorage.setItem('doctor', `${response.data.firstName} ${response.data.lastName}`);
          localStorage.setItem('firstName', `${response.data.firstName}`);
          localStorage.setItem('lastName', `${response.data.lastName}`);
          localStorage.setItem('email', `${response.data.email}`);

          localStorage.setItem('customer_id', '');
          localStorage.setItem('plan_name', '');
          localStorage.setItem('plan_code', '');
          localStorage.setItem('name', '');
          localStorage.setItem('current_term_starts_at', '');
          localStorage.setItem('current_term_ends_at', '');
          localStorage.setItem('interval_unit', '');
          localStorage.setItem('status', '');
          localStorage.setItem('currentDate', '');
          login(response.data);
          hideLoading();
          navigate('/dashboard');
        }
      }
      // Redirect to /subscriptions
      
    } catch (error) {
      if (error.response?.request?.status === 401) {
        hideLoading();
        showToast('info', 'Incorrect email or password');
      } 
    }
  };

  useEffect(() => {
    if ( formData.email && formData.password ) {

      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);


  return (
    <div className ="flex flex-col justify-center font-[sans-serif] text-[#333] sm:h-screen p-4">
    <div className ="max-w-md w-full mx-auto border border-gray-300 rounded-md p-6">
      <div className ="text-center mb-12">
        <a href=""><img
          src={require('../Ressources/Logo01.png')} alt="logo" className ='w-40 inline-block' />
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div className ="space-y-6">
          <div>
            <label className ="text-sm mb-2 block"><span style={{color: 'red'}} >*</span>Email</label>
            <input required name="email" onChange={handleChange} value={formData.email} type="text" className ="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter email" />
          </div>
          <div>
            <label className ="text-sm mb-2 block"><span style={{color: 'red'}} >*</span>Password</label>
            <input required name="password" onChange={handleChange} value={formData.password} type="password" className ="bg-white border border-gray-300 w-full text-sm px-4 py-3 rounded-md outline-blue-500" placeholder="Enter password" />
          </div>

        </div>
        <div className="!mt-10">
          <button 
            type="submit" 
            disabled={isButtonDisabled} 
            className={`w-full py-3 px-4 text-sm font-semibold rounded text-white ${isButtonDisabled === false ? " bg-blue-500 hover:bg-blue-700" : "bg-blue-300"}`}
          >
            Login
          </button>
        </div>
        <p className="text-sm mt-6 text-center">
            {/* Not have an account?  */}
            {/* <Link to="/register" className="text-blue-600 font-semibold hover:underline ml-1">Register here</Link> Use Link component here
            <br /> */}
            <Link to="/forgotpassword" className="text-blue-600 font-semibold hover:underline ml-1">Forgot password?</Link>
          </p>
      </form>
    </div>
  </div>
  )
}

export default Login
