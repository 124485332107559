import http from '../config/http';
import axios from 'axios';
const shortid = require('shortid');

export const FileUploader = async (file) => { // Ensure this function is async as well

    const filename = `${shortid.generate()} ${file.name}`;
    const contentype = file.type;
    const sourcefile = "scan";
    const patientname = "test";
    // console.log("File Name " + filename);

    const fetchPresignedUrl = async () => {
        try {
            const response = await http.get(`/uploadfile`, {
                params: {
                    patientname: encodeURIComponent(patientname),
                    filename: encodeURIComponent(filename),
                    contentype: encodeURIComponent(contentype),
                    sourcefile: encodeURIComponent(sourcefile),
                },
            });
            // console.log("URL " + response.data.url);
            return response.data.url; // Assuming the backend responds with { url: "..." }
        } catch (error) {
            // console.error("Error fetching presigned URL:", error);
            throw error;
        }
    };

    const uploadFileToS3 = async (url) => {
        try {
            const response = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type, // Or 'binary/octet-stream'
                },
            });
            // console.log("Response AWS Uploaded " + JSON.stringify(response));
            const uploadedFileUrl = url.split('?')[0];
            return uploadedFileUrl; // Check for successful response
        } catch (error) {
            // console.error("Error uploading file to S3:", error);
            throw error;
        }
    };

    try {
        const url = await fetchPresignedUrl(); // Await the URL
        // console.log("URL " + url);
        const success = await uploadFileToS3(url); // Await the upload result
        // console.log("Success " + success);
        return success;
    }
    catch (error) {
        // console.error("Error uploading file:", error);
        return false;
    }
};

export default FileUploader;
