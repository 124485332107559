import { useState, useEffect } from 'react';
import { FaUpload } from 'react-icons/fa';
import FileUploader from '../../config/uploadFile';
import http from  '../../config/http';

import { useLoading } from '../../context/contextLoading';
import { useToast } from '../../context/contextToast';
import { TabView, TabPanel } from 'primereact/tabview';

const PhotoGallery =  ( { onClose, data, onfetchServices } )=> {

    const showToast                                             = useToast();
    const { showLoading, hideLoading }                          = useLoading();
    const [ selectedFiles, setSelectedFiles ]                   = useState({
                                                                    photoGallery: null
                                                                });
    const [ fileNames, setFileNames ]                           = useState({
                                                                                photoGallery: ''
                                                                            });
    const [ servicesPhotoGallery, setServicesPhotoGallery ]     = useState([]);

    useEffect(() => {
        handlerRecords();
    }, [selectedFiles])
    


    const handleFileChange = async( e ) => {
        try{
            if( e.target.files.length  > 0 )
            {
                showLoading();
                var urlPhotoGallery = await FileUploader(e.target.files[0]);
                await http.post(`/services/photo_gallery`,{
                    service: data._id,
                    urlPhotoGallery
                });
                handlerRecords();
                hideLoading();
                
            }
        }catch(ex){
          console.log(e)
          console.log(ex)
        }
    };

    const handleIconClick = (e, inputId) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById(inputId).click();
    };


    const handlerRecords = async() => {
        const record = await http.get(`/services/photo_gallery/${data._id}`);
        setServicesPhotoGallery(record.data.data);
    };

    const handlePhotoClick = ( e, inputId ) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById(inputId).click();
    };

    const handlePhotoChange = async( e ) => {

        try{
            if( e.target.files.length  > 0 )
            {

                showLoading();
                var urlPhoto = await FileUploader(e.target.files[0]);
                console.log( data._id );
                await http.post(`/services/photo`,{
                    service: data._id,
                    picture: urlPhoto
                });
                data.picture = urlPhoto;
                onfetchServices();
                hideLoading();
                
            }
        }catch(ex){
          console.log(e)
          console.log(ex)
        }

    };


    return (
        <div className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '1200px'}}>
            <h2 className="text-2xl font-bold text-center mb-4">Photo Gallery</h2>
            
            <TabView>
                <TabPanel header="Photo Gallery">
                    <div style={{height: '550px', overflow: 'auto'}}>
                        
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px' }}>
                            {servicesPhotoGallery.map((row, index) => (
                                <div key={index} style={{ flex: '1 1 150px' }}>
                                <img src={row.urlPhotoGallery} alt={`Imagen ${index + 1}`} style={{ width: '100%' }} />
                                </div>
                            ))}
                        </div>

                    </div>
                    <hr />
                    <div>
                        <div className="p-4 border border-gray-300">
                        <div className="flex justify-between items-center mt-1 px-3 py-2 rounded-md" style={ selectedFiles.photoGallery !== null ? {border: '1px solid green'} : {borderColor: 'rgba(209, 213, 219, 1)'}}>
                            <label htmlFor="photoGallery" className="flex items-center">
                            <span>Photo</span>
                            <FaUpload onClick={(e) => handleIconClick(e, 'photoGallery')} className="ml-2 cursor-pointer" />
                            </label>
                            <input type="file" name="photoGallery" id="photoGallery" style={{ display: 'none' }} onChange={handleFileChange} accept="image/*" />
                            <span className="ml-auto">{fileNames.photoGallery}</span>
                        </div>
                        </div>
                    </div>
                </TabPanel>
                {
                    localStorage.getItem('role') === 'Office' ?
                    (
                        <TabPanel header="Profile Photo">
                            <div style={{height: '600px', overflow: 'auto', textAlign: 'center'}}>
                                <img src={data.picture} style={{margin: '0px auto'}} width={300} onClick={(e) => handlePhotoClick(e, 'photo')} />
                                <input type="file" name="photo" id="photo" style={{ display: 'none' }} onChange={handlePhotoChange} accept="image/*" />
                                <p>Click on the photo to change the profile photo.</p>
                            </div>
                        </TabPanel>
                    ):
                    (
                      <></>
                    )
                }
            </TabView>
            <hr />
            <div className="flex justify-between">
                <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Close
                </button>
            </div>
        </div>
    );

}

export default PhotoGallery;