import React, { useState, useEffect } from 'react'
import http from  '../config/http';

import { useLoading } from '../context/contextLoading';
import { useToast } from '../context/contextToast';

const Subscription = () => {

  const showToast                                 = useToast();
  const { showLoading, hideLoading }              = useLoading();
  const [setNumber, setSetNumber]                 = useState(localStorage.getItem('plan_name'));

  const [dataAccount, SetDataAccount]             = useState({
                                                    firstName: localStorage.getItem('firstName'),
                                                    lastName: localStorage.getItem('lastName'),
                                                    email: localStorage.getItem('email'),
                                                    plan_name: localStorage.getItem('plan_name'),
                                                    current_term_starts_at: localStorage.getItem('current_term_starts_at'),
                                                    current_term_ends_at: localStorage.getItem('current_term_ends_at'),
                                                    currentDate: localStorage.getItem('currentDate'),
                                                    customer_id: localStorage.getItem('customer_id')
                                                  });


  const handleSelectChange = async(event) => {
    setSetNumber(event.target.value);
  };

  const handlerSuscribirse = async() => {

    let jsonSus = {
        "customer_id": dataAccount.customer_id,
        "plan": {
          "plan_code": setNumber,
          quantity: 1
      }
    };
    showLoading();
    let urlCash = await http.put(`/services/subscriptions/new`, jsonSus);
    console.log( urlCash );
    if( urlCash.status === 200 ){
      if(urlCash.data.link){
        hideLoading();
        window.open(urlCash.data.data.hostedpage.url);
      }else{
        hideLoading();
        showToast('info', 'Notification', urlCash.data.message);
      }
      
    }

  }

  return (
    <>
      <div style={{width: '800px', height: '700px', padding:'10px', margin: '20px auto', border: '1px solid black', borderRadius: '5px'}}>

        <h2 style={{ marginBottom: '35px', fontSize: '24px', fontWeight: 'bold'}}>Subscriptions</h2>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">First Name:</label>
          <input disabled type="text" name="firstName" value={dataAccount.firstName} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Last Name:</label>
          <input disabled type="text" name="lastName" value={dataAccount.lastName} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Email:</label>
          <input disabled type="text" name="email" value={dataAccount.email} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Current Date:</label>
          <input disabled type="text" name="currentDate" value={dataAccount.currentDate} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Current Term Ends At:</label>
          <input disabled type="text" name="current_term_ends_at" value={dataAccount.current_term_ends_at} className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <div style={{marginBottom: '15px'}}>
          <label className="block text-sm font-medium text-gray-700">Plan:</label>
          <select disabled name='setNumber' value={setNumber} onChange={handleSelectChange} className="bg-white border border-gray-300  w-500 text-sm m-4 py-3 rounded-md outline-blue-500">
            <option key={'5393973000000089032'} value={'yearsubscr'}>Yearly Plan</option>
            <option key={'5393973000000089051'} value={'monthsubscr'}>Monthly Plan</option>
            <option key={'5393973000000090007'} value={'sub00'}>sub00</option>
          </select>
        </div>

        <div style={{marginBottom: '15px'}}>
          <button type="button" onClick={handlerSuscribirse} className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Subscriptions
          </button>
        </div>

      </div>
    </>
  );
};

export default Subscription;

