import { useEffect, useState }          from 'react';
import http                             from '../config/http';
import Spinner                          from '../components/Spinner';
import ServiceRow                       from '../components/ServiceRow'; // Import the new component
import ReviewTreatment                  from '../components/servicesPageComponents/ReviewTreatment';
import ConfirmApproval                  from '../components/servicesPageComponents/ConfirmApproval';
import ModificationRequest              from '../components/servicesPageComponents/ModificationRequest';
import UploadFormFile                   from '../components/servicesPageComponents/UploadFormFile';
import TreatmentComplete                from '../components/servicesPageComponents/TreatmentComplete';
import ConfirmRefinement                from '../components/servicesPageComponents/ConfirmRefinement';
import RecordsRemanufacturing           from '../components/servicesPageComponents/RecordsRemanufacturing';
import History                          from '../components/servicesPageComponents/History';
import TreatmentPrescription            from '../components/servicesPageComponents/TreatmentPrescription';
import PrintFile                        from '../components/servicesPageComponents/PrintFiles';
import ConfirmPrintFiles                from '../components/servicesPageComponents/ConfirmPrintFiles';
import ConfirmParcel                    from '../components/servicesPageComponents/ConfirmParcel';
import ConfirmationOfReceipt            from '../components/servicesPageComponents/ConfirmationOfReceipt';
import PhotoGallery                     from '../components/servicesPageComponents/PhotoGallery';
import AddPatienteSave                  from '../components/servicesPageComponents/AddPatienteSave';
import status                           from '../json/status.json';
import { useLoading }                   from '../context/contextLoading';
import { useToast }                     from '../context/contextToast';

function Dashboard() {

  const showToast                                                                   = useToast();
  const { showLoading, hideLoading }                                                = useLoading();
  const [servicesReal, setServicesReal]                                             = useState([]);
  const [services, setServices]                                                     = useState([]);
  const [expandedRows, setExpandedRows]                                             = useState({});
  const [isModalOpen, setIsModalOpen]                                               = useState(false);
  const [isModalOpenModifi, setIsModalOpenModifi]                                   = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm]                                 = useState(false);
  const [isModalOpenConfirmRefinement, setIsModalOpenConfirmRefinement]             = useState(false);
  const [isModalOpenRecordRemanufacturing, setIsModalOpenRecordRemanufacturing]     = useState(false);
  const [isModalOpenHistory, setIsModalOpenHistory]                                 = useState(false);
  const [isModalOpenPhotoGalley, setIsModalOpenPhotoGalley]                         = useState(false);
  const [isModalTreatmentPrescription, setIsModalTreatmentPrescription]             = useState(false);
  const [isModalPrintFile, setIsModalPrintFile]                                     = useState(false);
  const [currentStatus, setCurrentStatus]                                           = useState(0);
  const [search, setSearch]                                                         = useState('');
  const [formDate, setFormDate]                                                     = useState({
                                                                                        _id: "",
                                                                                        name: "",
                                                                                        dob: "",
                                                                                        healthInfo: "",
                                                                                        treatmentplan: ""
                                                                                      });
  const [setNumber, setSetNumber]                                                   = useState('0');
  const [formDateRow, setFormDateRow]                                               = useState({});
  const [formDateRows, setFormDateRows]                                             = useState({});
  const [ rol, setRol ]                                                             = useState(localStorage.getItem('role'));


  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    showLoading();
    try {
      const response = await http.get(`/services/0/`);
      if( rol !== 'Office' ){
        setServices(response?.data?.services.filter( x => x.status >= 0 && x.status < 100 ) || []);
        setServicesReal(response?.data?.services.filter( x => x.status >= 0 && x.status < 100 ) || []);
      }else{
        setServices(response?.data?.services.filter( x => x.status >= 0 ) || []);
        setServicesReal(response?.data?.services.filter( x => x.status >= 0 ) || []);
      }
    } catch (error) {
      console.error('Failed to fetch services:', error);
    } finally {
      hideLoading();
    }

  };

  const handleSelectChange = async(event) => {
    setSetNumber(event.target.value);
    showLoading();
    try {
      const response = await http.get(`/services/${event.target.value}/`);
      if( rol !== 'Office' ){
        setServices(response?.data?.services.filter( x => x.status >= 0 && x.status < 100 ) || []);
        setServicesReal(response?.data?.services.filter( x => x.status >= 0 && x.status < 100 ) || []);
      }else{
        setServices(response?.data?.services.filter( x => x.status >= 0 ) || []);
        setServicesReal(response?.data?.services.filter( x => x.status >= 0 ) || []);
      }
      
    } catch (error) {
      console.error('Failed to fetch services:', error);
    } finally {
      hideLoading();
    }

  };


  const toggleRow = (serviceId) => {
    const newExpandedRows = { ...expandedRows };
    newExpandedRows[serviceId] = !newExpandedRows[serviceId];
    setExpandedRows(newExpandedRows);
  };


  const onClose = () => setIsModalOpen(false);
   

  const handleStatusClick = (data) => {
    
    setCurrentStatus(data.status);
    setIsModalOpen(true);

      setFormDate({
        _id: data._id,
        _idPatiente: data.patient._id,
        name: data.name,
        dob: data.patient.dob,
        healthInfo: data.patient.healthInfo,
        treatmentplan: data.treatmentplan,
        modificationRequest: data.modificationRequest,
        status: data.status,
        upperMidline: data.upperMidline,
        lowerMidline: data.lowerMidline,
        canineRelation: data.canineRelation,
        molarRelation: data.molarRelation,
        archesToBeTreated: data.archesToBeTreated,
        treatmentPreferences: data.treatmentPreferences,
        files: data.files,
        confirmePrintFile: data.confirmePrintFile,
        setNumber: data.setNumber,
        tenant: data.tenant,
        paymentServiceId: data.paymentServiceId,
        scan: data.scan,
        xRay: data.xRay
      });

  };

  const handleStatusClick02 = (data) => {
    
    setCurrentStatus(7);
    setIsModalOpen(true);

      setFormDate({
        _id: data._id,

        _idPatiente: data.patient._id,
        name: data.name,
        dob: data.patient.dob,
        healthInfo: data.patient.healthInfo,
        treatmentplan: data.treatmentplan,
        modificationRequest: data.modificationRequest,
        status: data.status,
        upperMidline: data.upperMidline,
        lowerMidline: data.lowerMidline,
        canineRelation: data.canineRelation,
        molarRelation: data.molarRelation,
        archesToBeTreated: data.archesToBeTreated,
        treatmentPreferences: data.treatmentPreferences,
        files: data.files,
        confirmePrintFile: data.confirmePrintFile,
        setNumber: data.setNumber,
        tenant: data.tenant,
        paymentServiceId: data.paymentServiceId,
        scan: data.scan,
        xRay: data.xRay
      });

  };

  const renderModalBasedOnStatus = (status, data) => {
    switch ( status ) {
      case 'Draft':
        return <ModificationRequest onClose={onClose} data={data} />;
      case 1:
          return <UploadFormFile onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 2:
        return <ReviewTreatment onClose={onClose} data={data} onfetchServices={fetchServices} onSetIsModalOpenModifi={setIsModalOpenModifi} onSetIsModalOpenConfirm={setIsModalOpenConfirm} />;
      case 3:
        return <UploadFormFile onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 4:
        return <ConfirmApproval onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 5:
        return <PrintFile onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 6:
        return <ConfirmPrintFiles onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 7:
        return <TreatmentComplete onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 11:
        return <ConfirmParcel onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 12:
          return <PrintFile onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 13:
          return <ConfirmationOfReceipt onClose={onClose} data={data} onfetchServices={fetchServices} />;
      case 100:
          return <AddPatienteSave onClose={onClose} data={data} />;
      default:
        return <p>Status is unknown.</p>;
    }
  };

  const renderModalBasedOnStatusModifi = (data) => {
      return <ModificationRequest data={data} onSetIsModalOpenModifi={setIsModalOpenModifi} onfetchServices={fetchServices} />;
  };


  const renderModalConfirmApproval = (data) => {
    return <ConfirmApproval onClose={onClose} data={data} onfetchServices={fetchServices} onSetIsModalOpenConfirm={setIsModalOpenConfirm} />;
  };


  const renderModalTreatmentComplete = (data) => {
    setIsModalOpenConfirm(true);
    return <TreatmentComplete />;
  };

  const handleChange = async({ target }) => {
    setSearch( target.value );
    setServices( servicesReal.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(target.value.toUpperCase()))) );
  };

  // ================================================================================================================
  // ================================================================================================================

  const onCloseConfirmRefinement = () => setIsModalOpenConfirmRefinement(false);

  const statusConfirmRefinement = (service) => {
    setIsModalOpenConfirmRefinement(true)
    setFormDateRow(service)
  };

  const renderConfirmRefinement = (service) => {
    return <ConfirmRefinement onClose={onCloseConfirmRefinement} data={formDateRow} onfetchServices={fetchServices} />;
  };


  // ================================================================================================================

  const onCloseRecordRemanufacturing = () => setIsModalOpenRecordRemanufacturing(false)

  const statusRecordRemanufacturing = (service) => {
    setIsModalOpenRecordRemanufacturing(true)
    setFormDateRows(service)
  };

  const renderRecordRemanufacturing = (service) => {
    return <RecordsRemanufacturing onClose={onCloseRecordRemanufacturing} data={formDateRows} onfetchServices={fetchServices} />;
  };


  // ================================================================================================================
  const onCloseHistory = () => setIsModalOpenHistory(false)

  const statusHistory = (service) => {
    setIsModalOpenHistory(true)
    setFormDateRows(service)
  };

  const renderHistory = (service) => {
    return <History onClose={onCloseHistory} data={formDateRows} onfetchServices={fetchServices} />;
  };


  // ================================================================================================================
  const onCloseTreatmentPrescription = () => setIsModalTreatmentPrescription(false)

  const statusTreatmentPrescription = (service) => {
    setIsModalTreatmentPrescription(true);
    setFormDateRows(service)
  };

  const renderTreatmentPrescription = () => {
    return <TreatmentPrescription onClose={onCloseTreatmentPrescription} data={formDateRows} />;
  };



  // ================================================================================================================
  const onClosePhotoGalley = () => setIsModalOpenPhotoGalley(false)

  const statusPhotoGalley = (service) => {
    setIsModalOpenPhotoGalley(true)
    setFormDateRows(service)
  };

  const renderPhotoGalley = (service) => {
    return <PhotoGallery onClose={onClosePhotoGalley} data={formDateRows} onfetchServices={fetchServices} />;
  };

  return (
    <div className="flex justify-center mt-5">
      <div className="w-full max-w-[70%] shadow-md bg-white rounded-lg overflow-hidden">
      
        <div style={{display: 'flex'}}>
          <div style={{textAlign: 'left', width: '50%'}}>
            <label>Status:</label>
            <select name='setNumber' value={setNumber} onChange={handleSelectChange} className="bg-white border border-gray-300  w-500 text-sm m-4 py-3 rounded-md outline-blue-500">
            
            { localStorage.getItem('role') === 'toothxmaker' ? (<></>) : (<option key={0} value={0}>All status</option>) }
            {
              status.filter( x => x.role === localStorage.getItem('role')).map( (a) => <option key={a.status} value={a.status}>{a.display}</option> )
            }
            </select>
          </div>

          <div style={{textAlign: 'right', width: '50%'}}>
            <label>Search:</label>
            <input name="search" type="text" value={ search } onChange={ handleChange } className ="bg-white border border-gray-300  w-500 text-sm m-4 py-3 rounded-md outline-blue-500" />
          </div>

        </div>
        
        <hr />

        <div style={{height: '700px', overflow: 'auto'}}>
          <table className="min-w-full">
            <thead className="text-white" style={{background: 'black'}}>
              <tr>
                <th className="py-3 px-6 text-left">Patient Name</th>
                <th className="py-3 px-6 text-left">DOB</th>
                {/* <th className="py-3 px-6 text-left">Appointments</th> */}
                <th className="py-3 px-6 text-left">Refinement</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              { services.length === 0 ? (
                <tr>
                  <td colSpan={5} style={{textAlign:'center', fontWeight: 'bold'}}>No Records</td>
                </tr>
              ) : (
                  services.map((service) => (
                    <ServiceRow
                      key={service._id}
                      service={service}
                      isExpanded={!!expandedRows[service._id]}
                      toggleRow={toggleRow}
                      onClickStatus={handleStatusClick}
                      handleStatusClick02={handleStatusClick02}
                      onClickEnd={renderModalTreatmentComplete}
                      statusConfirmRefinement={statusConfirmRefinement}
                      statusRecordRemanufacturing={statusRecordRemanufacturing}
                      statusHistory={statusHistory}
                      statusTreatmentPrescription={statusTreatmentPrescription}
                      statusPhotoGalley={statusPhotoGalley}
                      onfetchServices={fetchServices}
                    />
                  ))
              ) }
            </tbody>
          </table>
        </div>

      </div>


      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderModalBasedOnStatus(currentStatus, formDate)}
        </div>
      </div>
      )}

      {isModalOpenModifi && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderModalBasedOnStatusModifi(formDate)}
        </div>
      </div>
      )}

      {isModalOpenConfirmRefinement && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderConfirmRefinement(formDateRow)}
        </div>
      </div>
      )}

      {isModalOpenRecordRemanufacturing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderRecordRemanufacturing(formDateRows)}
        </div>
      </div>
      )}

      {isModalOpenHistory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderHistory(formDateRows)}
        </div>
      </div>
      )}

      {isModalTreatmentPrescription && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderTreatmentPrescription()}
        </div>
      </div>
      )}

      {isModalOpenConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderModalConfirmApproval(formDate)}
        </div>
      </div>
      )}

      {isModalOpenPhotoGalley && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg text-gray-700"> {/* Ensures text color is dark against the light background */}
            {renderPhotoGalley(formDate)}
        </div>
      </div>
      )}




    </div>
  );
}

export default Dashboard;
