import React, { useState, useEffect } from 'react';
import http from  '../../config/http';
import { FaDownload, FaPrint, FaChevronDown, FaChevronUp, FaHistory, FaTable } from 'react-icons/fa'; // Ensure react-icons is installed

const RecordsRemanufacturing = ({onClose, data, onfetchServices}) => {

    
    const [services, setServices] = useState([]);

    useEffect(() => {
        handlerRecords();
      }, []);


    const handlerRecords = async() => {
        const record = await http.get(`/services/servicesrecordsremano/${data.patient._id}`);

        let arrayServ = [];
        record.data.data.map( ( row, index ) => {
            
            arrayServ = [ ...arrayServ, {

                id: index + 1,
                name: row.name,
                setNumberCurrent: row.setNumberCurrent,
                setNumber: row.setNumber,
                scan: row.scan,
                status: 'history'

            } ];
            
        } );

        arrayServ = [ ...arrayServ, {

            id: 0,
            name: data.name,
            setNumberCurrent: data.setNumberCurrent,
            setNumber: data.setNumber,
            scan: data.scan,
            status: 'Current'

        } ];

        setServices(arrayServ);
        
    };
    


    return(
        <>
            <div className="bg-white shadow-md rounded px-10 pt-6 pb-6 mb-4" style={{width: '900px'}}>
                
                <h2 className="text-2xl font-bold text-center mb-4">Treatment Records</h2>

                <div style={{overflow:'auto', height: '400px'}}>

                    <table className="" style={{width: '800px'}}>
                        <thead className="text-white" style={{background: 'black'}}>
                        <tr>
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Completed Sets</th>
                            <th className="py-3 px-6 text-left">Treatment Sets</th>
                            <th className="py-3 px-6 text-left">Scan</th>
                            <th className="py-3 px-6 text-left">Status</th>
                            {/* <th className="py-3 px-6 text-left">Status</th> */}
                        </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {
                                services.sort((a, b) => a.id - b.id).map( (row) => (
                                    <tr>
                                        <td> {row.name} </td>
                                        <td> {row.setNumberCurrent} </td>
                                        <td> {row.setNumber} </td>
                                        <td> <a href={row.scan} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a></td>
                                        <td> {row.status} </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>

                <div className="flex justify-between">
                <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Treatment In Process
                </button>
            </div>

            </div>
        </>
    )

}



export default RecordsRemanufacturing;