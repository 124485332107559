import React, { useState } from 'react';
import http from  '../../config/http';
// import { FaUpload } from 'react-icons/fa';


const TreatmentComplete = ({ onClose, data, onfetchServices }) => {
  
  const handleConfirmPrintFile = async() => {
    await http.put(`/services/treatmentcomplete/${data._id}`);
    onfetchServices();
    onClose();
  };

  return (
    <>
      <div>
        <h1 style={{fontSize: '25px'}}>Treatment Complete</h1>

        <br/>
        <p>Are you sure to finish the treatment?</p>
        <br/>

        <div className="flex justify-between">
          <button type="button" onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Treatment In Process
          </button>&nbsp;
          <button type="button" onClick={handleConfirmPrintFile} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Confirm Treatment Complete
          </button>
        </div>

      </div>
    </>
  );
};

export default TreatmentComplete;