import React, { useState } from 'react';
import http from  '../../config/http';
import { FaDownload, FaPrint, FaChevronDown, FaChevronUp, FaHistory, FaTable, FaMarker, FaCheckDouble  } from 'react-icons/fa';

import { Calendar } from 'primereact/calendar';

const ConfirmPrintFiles =  ({onClose, data, onfetchServices, onSetIsModalOpenConfirm })=> {

    // console.log( data )
    const [formData, setFormData] = useState({
    name: data.name,
    dob: data.dob.substring(0,10), // Date of birth
    healthInfo: '',
    printFile: null 
    });

  
  const handleConfirmPrintFile = async() => {
    await http.put(`/services/unlockprintfileconfirme/${data._id}`);
    onClose();
    onfetchServices();
  };


  const handleClose = () => {
    // onSetIsModalOpenConfirm(false);
    onClose();
  };

  
  const handlefecha = (e) => {
    const dateString = e;  // Formato: dd/mm/yyyy
    console.log( dateString )
    const [ year, month, day ] = dateString.split("-");  // Separar día, mes y año
    const dateObject = new Date(year, month - 1, day);  // Crear objeto Date (mes - 1 porque los meses en JS empiezan en 0)
    return dateObject;
  };


  return (

    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{width: '800px'}}>
      <h2 className="text-2xl font-bold text-center mb-4">Print Files</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Patient Name:</label>
          <input disabled type="text" name="name" id="name" value={formData.name} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        
        {/* <div>
          <label className="block text-sm font-medium text-gray-700">Patient Date of Birth:</label>
          <input disabled type="date" name="dob" id="dob" value={formData.dob} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div> */}

        <div>
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700"><span style={{color: 'red'}}>*</span>Patient Date of Birth:</label>
            <Calendar 
              name="dob" 
              id="dob" 
              value={handlefecha(formData.dob)}
              className="mt-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm" 
              style={ formData.dob !== '' ? {border: '1px solid green', width: "100%"} : {borderColor: 'rgba(209, 213, 219, 1)', width: "100%"}}
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
              disabled
              showIcon 
            />
          </div>

        <div>
            <div className="p-1 flex items-center">Download Print File <a href={data.files} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a></div>
        </div>
        <div className="flex justify-between">
          <button type="button" onClick={handleClose} className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
          </button>
          {/* disabled = {(!confirmation)? "disabled" : ""} */}
          <button type="button" onClick={handleConfirmPrintFile}  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Treatment Ready
          </button>
        </div>

      </form>
    </div>
  );
};

export default ConfirmPrintFiles;