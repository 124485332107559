import React, { useState, useEffect } from 'react';
import http from  '../config/http';
import { FaDownload, FaPrint, FaChevronDown, FaChevronUp, FaHistory, FaTable, FaMarker, FaCheckDouble, FaEnvelope, FaFilePdf, FaImage } from 'react-icons/fa'; // Ensure react-icons is installed

import status from '../json/status.json'

const ServiceRow = ({ service, isExpanded, toggleRow, onClickStatus, handleStatusClick02, onClickEnd, statusConfirmRefinement, statusRecordRemanufacturing, statusHistory, statusTreatmentPrescription, onfetchServices, statusPhotoGalley }) => {

  const [ payment, setPayment ] = useState(0);

  useEffect(() => {
  
    const dataPayment = async() => {
      
      if( localStorage.getItem('role') === 'toothxlabs' && service.status === 12 && service.paymentServiceId !== undefined ){
        const payment = await http.get(`/services/billing_invoices/${service.paymentServiceId}`);
        if( payment.status === 200 ){
          if( payment?.data.status === 1 ){
            setPayment(1);
          }
        }

      }

    };
    
    dataPayment();

  }, [])
  

  const [setNumber, setSetNumber] = useState(service.setNumberCurrent);

  const optionElements = [];
  for (let i = 0; i <= service.setNumber; i++) {
    optionElements.push(
      <option key={i} value={i}>{i}</option>
    );
  }


  const handleSelectChange = async(event) => {

    setSetNumber(event.target.value);
    await http.put(`/services/myzmilessetnumber/${service._id}`, {setNumberCurrent: event.target.value });
    onfetchServices();
  };


  const handlePrintSupplier = async() => {
    await http.put(`/services/myzmilesprint/${service._id}`, {});
  }


  const handleTrackingNumber = ( trackingNumber ) => {
    alert(` Tracking Number: ${trackingNumber}`);
  };

  return (
    <>
      <tr>

        <td className="py-3 px-6 text-left">
          {service.patient.name}
        </td>

        <td className="py-3 px-6 text-left">
          {service.patient.dob.substring(0, 10)}
        </td>

        <td>
          {service.remanufacturing === false ? '' : <FaHistory />}
        </td>

        <td>
          {
            status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).type === 'text' ?
            (
              <label
                style={status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).style}
              >
                {status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).display}
              </label>
            )
            :

            service.status === 12 && localStorage.getItem('role') === 'toothxlabs' && payment === 0
            ? 
              (<div className="p-1 flex items-center"><label style={{ fontWeight: 'bold', color: 'red'}}>Payment Not Made</label></div>)
              // (
              //   <button 
              //     className="px-3 py-1 rounded"
              //     style={status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).style}
              //     onClick={() => onClickStatus(service)}
              //   >
              //     {status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).display}
              //   </button>
              // )
            :
              status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).type === 'button' ?
              (
                <button 
                  className="px-3 py-1 rounded"
                  style={status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).style}
                  onClick={() => onClickStatus(service)}
                >
                  {status.find( x => x.status === service.status && x.role === localStorage.getItem('role')).display}
                </button>
              )


            :
            (
              <></>
            )
          }
        </td>

        <td className="py-3 px-6 text-left">
          {
            service.status === 100 ? 
            (
              <></>
            ):
            (
              <div className="text-blue-500 hover:text-blue-700 cursor-pointer" onClick={() => toggleRow(service._id)}>
                {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            )
          }
          
        </td>

      </tr>

      {isExpanded && (
        <tr>
          <td colSpan="5" className="bg-gray-100">
            <div className="flex justify-around py-3">
              <div className="flex items-center">
                {/* Avatar only shown here in the expanded section */}
                <img
                  src={service.picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(service.patient.firstName)}+${encodeURIComponent(service.patient.lastName)}`}
                  alt="Patient"
                  className="w-20 h-20 rounded-full mr-2"
                />
                <strong>{service.patient.firstName} {service.patient.lastName}</strong>
              </div>
              <div>
                <strong>Last Appointment:</strong>
                <div className="p-1 flex items-center"><prep>Patient ID: <span style={{ fontWeight: 'bold' }}>{ service.patient._id }</span></prep></div>
                <div className="p-1 flex items-center">Treatment Prescription <FaCheckDouble onClick={ () => statusTreatmentPrescription(service) } className="ml-2 cursor-pointer" /></div>
                <div className="p-1 flex items-center">Patient history view<FaMarker onClick={ () => statusHistory(service) } className="ml-2 cursor-pointer" /></div>
                <div className="p-1">
                  Delivered Sets: &nbsp;
                  <select id='setNumber' name='setNumber' value={setNumber} onChange={handleSelectChange} style={{width:'50px'}} disabled={service.confirmePrintFile === true ? false: true}>
                    {optionElements}
                  </select>
                  &nbsp; out of {service.setNumber}
                </div>
                <div className='p-1'>
                  {
                    localStorage.getItem('role') === 'Office' ?
                    (
                      <button className="px-3 py-1 rounded" style={{backgroundColor: 'green', color: 'white'}} onClick={() => handleStatusClick02(service)} > End Treatment </button>
                    ):
                    (
                      <></>
                    )
                  }
                  
                </div>
              </div>
              <div>
                <strong>Files:</strong>
                <div className="p-1 flex items-center">Patient Scan <a href={service.scan} download target="_blank" style={{marginRight: '5px'}}><FaDownload className="ml-2 cursor-pointer" /></a> X-Ray <a href={service.xRay} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a></div>
                <div className="p-1 flex items-center">Patient Treatment Plan { service.status === 2 || service.status === 4 || service.status === 5 ? ( <a href={service.forms} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a> ) : ( <></> ) }</div>
                <div className="p-1 flex items-center">Shipping Information { service.trackingNumber?.length > 0 ? ( <div className="p-1 flex items-center"><FaEnvelope className="ml-2 cursor-pointer" onClick={ (e) => handleTrackingNumber(service.trackingNumber)} /> <a href={service.pdfParcel} download target="_blank"><FaFilePdf className="ml-2 cursor-pointer" /></a> </div>  ) : ( <></> ) }</div>
                <div className="p-1 flex items-center">Photo Gallery <FaImage className="ml-2 cursor-pointer" onClick={() => statusPhotoGalley(service)} /></div>
              </div>
              <div>
                <strong>Actions:</strong>
                <div className="p-1 flex items-center">Download Print File { service.confirmePrintFile || localStorage.getItem('role') === 'toothxmaker' ? ( <a href={service.files} download target="_blank"><FaDownload className="ml-2 cursor-pointer" /></a> ) : ( <></> ) }</div>
                <div className="p-1 flex items-center">Request Refinement<FaHistory className="ml-2 cursor-pointer" onClick={() => statusConfirmRefinement(service)} /> {service.remanufacturing === undefined ? '' : <></>} </div>
                <div className="p-1 flex items-center">Treatment Records<FaTable className="ml-2 cursor-pointer" onClick={ () => statusRecordRemanufacturing(service) } /></div>
                {/* <div className="p-1 flex items-center">Request Aligner Manufacturing { service.status === 5 ? ( <FaPrint className="ml-2 cursor-pointer" onClick={handlePrintSupplier} /> ) : ( <></> ) }</div> */}
                { localStorage.getItem('role') === 'toothxlabs' && service.status === 12 && payment === 0 ? (<div className="p-1 flex items-center"><label style={{ fontWeight: 'bold', color: 'red'}}>Payment Not Made</label></div>) : (<></>) }

                
              </div>
            </div>
          </td>
        </tr>
      )}
      
    </>
  );
};

export default ServiceRow;
